import { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../../Pagination"
import DonationCard from "../DonationCard";
import DonateSection from "../DonateSection";
import PageLoading from "../../PageLoading";
import { Empty, message } from 'antd';
import coverImage from '../../../resources/images/donation.jpg'
import { getDonationCauses } from "../../api";
import "./style.css";


const headImage = {
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage})`,
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: 'cover',
}
const DonationPage = (props) => {
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(3)
  const [limit, setLimit] = useState(9)

  const incrimentPage = () => {
    setCurrentPage((page) => page + 1)
  }

  const decrementPage = () => {
    setCurrentPage((page) => page - 1)
  }

  const setPage = (page) => {
    setCurrentPage(page)
  }
  const fetchDonations = () => {
    setLoading(true)
    axios
      .get(getDonationCauses(), { params: { pageNo: currentPage - 1, limit } })
      .then((donations) => {
        setDonations(donations.data.data.donationCauses);
        setTotalCount(donations.data.data.totalCount)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        message.error("Unable to load Donations")
      });
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    fetchDonations()
  }, [currentPage]);

  return (
    <div>
      <PageLoading loading={loading} />
      <div className="articles-banner">
        <div className="articles-img" style={headImage}>
          <div className="articles-banner-title-wrap">
            <h1 className="articles-banner-title">Donations</h1>
          </div>
        </div>
      </div>
      <DonateSection />
      <div className="events-upcoming-container">
        <div className="events-upcoming">
          <h6 className="events-upcoming-subtitle">FEATURE CAUSES</h6>
          <h2 className="events-upcoming-title">
            You Can Help Lots of People by <br /> Donating Little
          </h2>
        </div>
      </div>

      {donations.length == 0 ? <Empty className="empty-icon" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
        <span>
          No Donations Present
        </span>
      } /> : null}
      <div className="articles-cards-wrapper">
        <div className="articles-cards-container">
          <div className="articles-cards-row">
            <div className="articles-cards-col">
              <div className="articles-cards-inner">
                {donations.map((donation) => (
                  <DonationCard key={donation._id} {...{ ...donation, ...props.match }} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        goToNextPage={incrimentPage}
        goToPreviousPage={decrementPage}
        changePage={setPage}
        currentPage={currentPage}
        totalCount={totalCount}
        pageLimit={pageLimit}
        limit={limit}
      />
    </div>
  );
};

export default DonationPage;
