import jwt_decode from "jwt-decode";

const getUser = (token) => {
    let decoded = jwt_decode(token);
    if (decoded) {
        return {
            isLoggedIn: true,
            email: decoded.email,
            role: decoded.role,
            id: decoded.sub
        }
    } else return null
}
export default getUser;