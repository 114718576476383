import { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../../Pagination"
import PageLoading from "../../PageLoading";
import EventCard from "../EventCard"
import coverImage from '../../../resources/images/event.jpg'
import { Empty, message } from 'antd';
import { getEvents } from "../../api";
import "./style.css";


const headImage = {
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage})`,
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: 'cover',
}
const EventsPage = (props) => {
  const [events, setEvents] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(3)
  const [limit, setLimit] = useState(8)

  const incrimentPage = () => {
    setCurrentPage((page) => page + 1)
  }

  const decrementPage = () => {
    setCurrentPage((page) => page - 1)
  }

  const setPage = (page) => {
    setCurrentPage(page)
  }

  const fetchEvents = () => {
    setLoading(true)
    axios
      .get(getEvents(), { params: { pageNo: currentPage - 1, limit } })
      .then((events) => {
        setEvents(events.data.data.events);
        setTotalCount(events.data.data.totalCount)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        message.error("Unable to load Event Details")
      });
  };

  useEffect(() => {
    fetchEvents()
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  }, [currentPage]);

  return (
    <div>
      <PageLoading loading={loading} />
      <div className="events-banner">
        <div className="events-img" style={headImage}>
          <div className="events-banner-title-wrap">
            <h1 className="events-banner-title">Our Events</h1>
          </div>
        </div>
      </div>

      <div className="events-upcoming-container">
        <div className="events-upcoming">
          <h6 className="events-upcoming-subtitle">UPCOMING EVENTS</h6>
          <h2 className="events-upcoming-title">
            Don't Miss Your Chance to Get <br /> Closer to God
            </h2>
        </div>
      </div>
      {events.length == 0 ? <Empty className="empty-icon" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
        <span>
          No Events Present
      </span>
      } /> : null}
      <div className="events-cards-wrapper">
        <div className="events-cards-container">
          <div className="events-cards-row">
            <div className="events-cards-col">
              <div className="events-cards-inner">
                {events.map((event) => (
                  <EventCard {...{ key: event._id, ...event, ...props.match }} />
                ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        goToNextPage={incrimentPage}
        goToPreviousPage={decrementPage}
        changePage={setPage}
        currentPage={currentPage}
        totalCount={totalCount}
        pageLimit={pageLimit}
        limit={limit}
      />
    </div>
  );
};

export default EventsPage;
