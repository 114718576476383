import { useState, useEffect } from "react";
import axios from "axios";
import PageLoading from "../../PageLoading";
import { message } from "antd";
import { getArticles } from "../../api";
import ArticleCardLanding from "./ArticleCardLanding";
import "./style.css";

const ArticleSection = () => {
	const [articles, setArticles] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchArticles = () => {
		setLoading(true);
		axios
			.get(getArticles(), { params: { limit: 3 } })
			.then((events) => {
				setArticles(events.data.data.articles);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				message.error("Unable to load Article Details");
			});
	};

	useEffect(() => {
		fetchArticles();
	}, []);

	return (
		<div className="home-article-container">
			<PageLoading loading={loading} />
			<div className="home-article-content">
				{articles.length > 0 && articles.map((article) => (
					<ArticleCardLanding key={article._id} {...{ ...article }} />
				))}
			</div>
			<div className="home-article-header">
				<p className="home-text-caption" style={{ color: "#000" }}>OUR ARTICLES</p>
				<h1 className="home-text-main home-article-header-main" >Latest from Articles</h1>
			</div>
		</div>
	)
};

export default ArticleSection