import { useHistory } from "react-router-dom";
import { Button } from "antd"
import coverImage from "../../../resources/images/subscription.jpg";

const headImage = {
  background: `url(${coverImage}) no-repeat center`,
  backgroundSize: "cover",
};

const SubscriptionCancelPage = () => {
  const history = useHistory();
  return (
    <div className="subscription-page-container">
      <div className="subscription-banner">
        <div className="subscription-img" style={headImage}>
          <div className="subscription-banner-title-wrap">
            <h1 className="subscription-banner-title">Subscription</h1>
          </div>
        </div>
      </div>
      <div className="subscription-container subscription-gen-container">
        <div className="subscription-form-container">
          <h1 className="subscription-form-title">
            Magazine Subscription Form
          </h1>
          <h2 className="subscription-form-sub-title">
            Payment Failed.
          </h2>
          <Button type="primary" onClick={() => history.push("/subscription")}>Try Again</Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCancelPage;
