import { useHistory } from "react-router-dom";
import { Button } from "antd"
import coverImage from "../../../resources/images/donation.jpg";

const headImage = {
  background: `url(${coverImage}) no-repeat center`,
  backgroundSize: "cover",
};

const DonationCancelPage = () => {
  const history = useHistory();
  return (
    <div className="donation-page-container">
      <div className="donation-banner">
        <div className="donation-img" style={headImage}>
          <div className="donation-banner-title-wrap">
            <h1 className="donation-banner-title">Donation</h1>
          </div>
        </div>
      </div>
      <div className="donation-container donation-gen-container">
        <div className="donation-form-container">
          <h1 className="donation-form-title">
            Donation
          </h1>
          <h2 className="donation-form-sub-title">
            Payment Failed.
          </h2>
          <Button type="primary" onClick={() => history.push("/donation")}>Try Again</Button>
        </div>
      </div>
    </div>
  );
};

export default DonationCancelPage;
