import "./style.css"
const Pagination = (props) => {

    const getPaginationGroup = () => {
        let pages = props.pageLimit > Math.ceil(props.totalCount / props.limit)?Math.ceil(props.totalCount / props.limit):  props.pageLimit
        let start = Math.floor((props.currentPage - 1) / pages) * pages;
        return new Array(pages).fill().map((_, idx) => start + idx + 1);
    };

    return (
        <div className="pagination-container">
            <span
                onClick={props.goToPreviousPage}
                className={`page-numbers ${props.currentPage === 1 ? 'page-disabled' : 'non-active'}`}
            >
                Prev
            </span>

            {getPaginationGroup().map((item, index) => (
                <span
                    key={index}
                    onClick={(e) => { props.changePage(Number(e.target.innerText)) }}
                    className={`page-numbers ${props.currentPage === item ? 'current-page' : "non-active"}`}
                >
                    {item}
                </span>
            ))}

            <span
                onClick={props.goToNextPage}
                className={`page-numbers ${props.currentPage > Math.ceil(props.totalCount / props.limit)-1 ? 'page-disabled' : 'non-active'}`}
            >
                Next
            </span>
        </div>
    );
}

export default Pagination;