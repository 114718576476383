import { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { message } from 'antd'
import debounce from 'lodash.debounce'
import PageLoading from "../../PageLoading";
import LeftArrow from "../../../resources/Icons/left-arrow.svg"
import RightArrow from "../../../resources/Icons/right-arrow.svg"
import ContributionCard from "../ContributionCard/index"
import ContributionBanner from "../../../resources/images/about-us-contribution.svg"
import { getContributions } from "../../api"
import './style.css'

const Contributions = (props) => {
    let current
    const [hasOverFlow, setHasOverflow] = useState(false);
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)
    const [scrollbarWidth, setScrollbarWidth] = useState("0%")
    const [contributions, setContributions] = useState([])
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0)
    const container = useRef(null)

    const checkForOverflow = () => {
        const { scrollWidth, clientWidth } = container.current
        const overflow = scrollWidth > clientWidth
        setHasOverflow(overflow);
    }

    const checkForScrollPosition = () => {
        const { scrollLeft, scrollWidth, clientWidth } = container.current
        setScrollbarWidth(`${(clientWidth + scrollLeft) * 100 / scrollWidth}%`)
        setCanScrollLeft(scrollLeft > 0)
        setCanScrollRight(scrollLeft + 10 <= scrollWidth - clientWidth)
    }

    const debounceCheckForScrollPosition = debounce(checkForScrollPosition, 100)

    const scrollContainerBy = (distance) => {
        container.current.scrollBy({ left: distance, behavior: 'smooth' })
    }
    const fetchContributions = () => {
        setLoading(true)
        axios
            .get(getContributions())
            .then((contributions) => {
                setContributions(contributions.data.data.contributions);
                setTotalCount(contributions.data.data.totalCount)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                message.error("Unable to load Contributions")
            });
    };

    useEffect(() => {
        checkForOverflow()
        checkForScrollPosition()
        fetchContributions()
        current = container.current
        container.current.addEventListener(
            'scroll',
            debounceCheckForScrollPosition
        )
        return () => {
            current.removeEventListener(
                'scroll',
                debounceCheckForScrollPosition
            )
        }
    }, [])

    return (
        <>
            <PageLoading loading={loading} />
            <section className="about-us-contributions">
                <div className="contributions-container">
                    <img src={ContributionBanner} alt="Contribution banner" />
                    <div className="contributions-orange-bg"></div>
                </div>
            </section>
            <section className="contributions-inner" id="contributions">
                <div className="contributions-header">
                    <p className="section-caption text-color-white">OUR CONTRIBUTIONS</p>
                    <h2 className="section-header text-color-white">Delivering help at the Right time</h2>
                    <div className="contributions-arrow-container">
                        <button
                            className={canScrollLeft ? "contributions-arrow" : "contributions-arrow-disabled"}
                            disabled={!canScrollLeft}
                            onClick={() => scrollContainerBy(-450)}>
                            <img src={LeftArrow} alt="left" />
                        </button>
                        <button
                            className={canScrollRight ? "contributions-arrow" : "contributions-arrow-disabled"}
                            disabled={!canScrollRight}
                            onClick={() => scrollContainerBy(450)}>
                            <img src={RightArrow} alt="right" />
                        </button>
                    </div>
                </div>
                <div ref={container} className="contributions-card-container">
                    {contributions.map((contribution) => (
                        <ContributionCard key={contribution._id} {...{ ...contribution, ...props.match }} />
                    ))}
                </div>
                <div className="contributions-scrollbar">
                    <div className="contributions-scrollbar-thumb" style={{ width: scrollbarWidth }} ></div>
                </div>
            </section>
        </>
    )
}

export default Contributions;
