import React from 'react';
import Navbar from '../components/Navbar'
import Featured from '../components/Featured'
import ArticlesPage from '../components/Articles/ArticlesPage'
import Article from '../components/Articles/Article'
import MagazinesPage from '../components/Magazines/MagazinesPage'
import EventsPage from '../components/Events/EventsPage'
import Event from '../components/Events/Event'
import UnsubscribePage from '../components/Magazines/UnsubscribePage'
import SubscriptionPage from '../components/Subscription/SubscriptionPage'
import SubscriptionSuccessPage from '../components/Subscription/SubsciptionSuccessPage'
import SubscriptionCancelPage from '../components/Subscription/SubscriptionCancelPage'
import DonationPage from '../components/Donation/DonationPage'
import DonationGeneral from '../components/Donation/DonationGeneral'
import DonationCause from '../components/Donation/DonationCause'
import DonationSuccessPage from '../components/Donation/DonationSuccessPage'
import DonationCancelPage from '../components/Donation/DonationCancelPage'
import GalleryPage from '../components/Gallery/GalleryPage'
import PageNotFound from '../components/PageNotFound'
import HomePage from '../components/HomePage'
import AboutUsPage from '../components/AboutUs/';
import Footer from '../components/Footer';
import ContactUs from '../components/ContactUs'
import StatementOfFaith from "../components/AboutUs/StatementOfFaith"
import { Redirect, Route, Switch } from 'react-router-dom';

function PublicRoutes() {
    return (
        <>
            <Navbar />
            <Switch>
                <Route path="/article/:id" children={<Article />} />
                <Route path="/article" exact component={ArticlesPage} />
                <Route path="/magazine" exact component={MagazinesPage} />
                <Route path="/event/:id" children={<Event />} />
                <Route path="/event" exact component={EventsPage} />
                <Route path="/featured/:id" children={<Featured />} />
                <Route path="/magazine/unsubscribe/:id" children={<UnsubscribePage />} />
                <Route path="/subscription/success" component={SubscriptionSuccessPage} />
                <Route path="/subscription/canceled/" component={SubscriptionCancelPage} />
                <Route path="/subscription" exact component={SubscriptionPage} />
                <Route path="/donation" exact component={DonationPage} />
                <Route path="/donation/general" exact component={DonationGeneral} />
                <Route path="/donation/success" component={DonationSuccessPage} />
                <Route path="/donation/canceled" component={DonationCancelPage} />
                <Route path="/donation/:id" component={DonationCause} />
                <Route path="/gallery" component={GalleryPage} />
                <Route path="/contact-us" component={ContactUs} />
                <Route path="/about-us/statement-of-faith" component={StatementOfFaith} />
                <Route path="/about-us" component={AboutUsPage} />
                <Route path="/" exact component={HomePage} />
                <Route exact path="/404" component={PageNotFound} />
                <Redirect from="*" to="/404" />
            </Switch>
            <Footer />
        </>
    );
}

export default PublicRoutes;
