import ClipLoader from "react-spinners/ClipLoader";
import "./style.css"
const PageLoading = (props) => {

    return (
        <div className={`page-loading ${!props.loading ? "loading-display" : ""}`}>
            <div className="loading-indicator">
                <ClipLoader color="#fc8e44" loading={props.loading} size={50} />
            </div>
        </div>
    );
}

export default PageLoading;