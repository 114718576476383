import { useState } from 'react'
import { Form, Input, Button, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import BarLoader from "react-spinners/BarLoader";
import { useParams, useHistory } from "react-router-dom";
import avatar from "../../../resources/images/avatar.png";
import { resetPassword } from "../../api"
import axios from 'axios';


const ResetPassword = () => {
    const { resetToken } = useParams();
    const [loading, setLoading] = useState(false)
    const [errorStatus, setErrorStatus] = useState("")
    const [errorStatusConfirm, setErrorStatusConfirm] = useState("")
    const [helpText, setHelpText] = useState("")
    const history = useHistory();

    const checkPasswordStrength = (e) => {
        const passwordRegex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;
        if (!passwordRegex.test(e.target.value) && e.target.value) {
            setErrorStatus("error")
            setHelpText("Password shoud contain minimum 8 chars, at least 1 upper case, 1 special character and 1 number.")
        } else {
            setErrorStatus("")
            setHelpText("")
        }
    }

    const onPasswordChange = () => {
        setErrorStatus("")
        setErrorStatusConfirm("")
        setHelpText("")
    }

    const onFinish = (values) => {
        if (values.password == values.passwordConfirm) {
            const passwordRegex = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;
            if (passwordRegex.test(values.password)) {
                setLoading(true)
                let credentials = {
                    resetToken,
                    password: values.password
                }
                axios.post(resetPassword(), credentials).then(response => {
                    setLoading(false)
                    message.success("Password reset success. Please Login")
                    history.push("/login")
                }).catch(err => {
                    setLoading(false)
                    if (err.response.status == 404) message.error(err.response.data.message)
                    else message.error(err.response.data.message)
                })
            } else {
                message.error("Weak Password.")
            }
        } else {
            message.error("Your passwords entered does not match.")
            setErrorStatus("error")
            setErrorStatusConfirm("error")
            setHelpText("Passwords do not match.")
        }
    };
    return (
        <div>
            <div className="login-container">
                <div className="login-form-container">
                    <img
                        width={100}
                        height={100}
                        src={avatar}
                        alt="avatar"
                        className="login-avatar"
                    />

                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="password"
                            onChange={checkPasswordStrength}
                            validateStatus={errorStatus}
                            help={helpText}
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="New Password"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="passwordConfirm"
                            onChange={onPasswordChange}
                            validateStatus={errorStatusConfirm}
                            rules={[{ required: true, message: 'Please confirm your Password!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Confirm Password"
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                {loading ? <BarLoader color="#fff" loading={true} height={8} width={100} /> : "Reset Password"}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>

    );
}

export default ResetPassword;

