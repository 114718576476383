import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getBaseUrl } from '../../api'
import { getYear } from '../../utils'
import ContributionView from '../../DashBoard/ContributionsDashboard/ContributionView'
import './style.css'
const ContributionCard = (props) => {
    const [vewContributionId, setViewContributionId] = useState(null)
    const [vewContribution, setViewContribution] = useState(false)
    return (
        <div className="contribution-card-container">
            <ContributionView
                {...props}
                setVisible={(state) => setViewContribution(state)}
                id={vewContributionId}
                visible={vewContribution}
            />
            <img src={`${getBaseUrl()}${props.image}`} alt="Card" />
            <div>
                <div className="contribution-card-content">
                    <p className="contribution-card-caption">{getYear(props.date)}</p>
                    <Link className="contribution-card-heading" onClick={() => {
                        setViewContribution(true)
                        setViewContributionId(props._id)
                    }}>
                        {props.title}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ContributionCard;
