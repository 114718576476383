export default {
    // private
    dashboard: '/dashboard',
    events: '/dashboard/events',
    authors: '/dashboard/authors',
    users: '/dashboard/users',
    magazines: '/dashboard/magazines',
    featured: '/dashboard/featured',
    mails: '/dashboard/mails',
    mailsCreateAndSend: '/dashboard/mailings/mails/create-send',
    mailingAddress: '/dashboard/mailing-address',
    settings: '/dashboard/settings',
    donationCause: '/dashboard/donations/cause',
    addDonationCause: '/dashboard/donations/cause/add',
    updateDonationCause: '/dashboard/donations/cause/update/:id',
    updateDonationCauseCheck: '/dashboard/donations/cause/update/',
    donationHistory: '/dashboard/donations/history',
    subscription: '/dashboard/subscription',
    address: '/dashboard/subscription/address',
    plans: '/dashboard/subscription/plans',
    subscribers: '/dashboard/subscription/subscribers',
    articles: '/dashboard/articles',
    addArticle: '/dashboard/articles/add',
    updateArticle: '/dashboard/articles/update/:id',
    updateArticleCheck: '/dashboard/articles/update/',
    devotions: '/dashboard/devotions',
    addDevotion: '/dashboard/devotions/add',
    updateDevotion: '/dashboard/devotions/update/:id',
    updateDevotionCheck: '/dashboard/devotions/update/',
    contributions: '/dashboard/contributions',
    addContribution: '/dashboard/contributions/add',
    updateContribution: '/dashboard/contributions/update/:id',
    updateContributionCheck: '/dashboard/contributions/update/',
};
