import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { createUseStyles, useTheme } from 'react-jss';
import { SidebarComponent, SidebarContext } from '../components/DashBoard/sidebar';
import HeaderComponent from '../components/DashBoard/header/HeaderComponent';
import PrivateRoutes from './PrivateRoutes';
import { useDispatch } from "react-redux";
import getUser from "../components/getUser"
import { setUser } from "../redux"

const useStyles = createUseStyles({
    container: {
        height: '100%',
        minHeight: 850
    },
    mainBlock: {
        marginLeft: 255,
        padding: 30,
        '@media (max-width: 1080px)': {
            marginLeft: 0
        }
    },
    contentBlock: {
        marginTop: 54
    }
});

const PrivateSection = () => {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles({ theme });
    const dispatch = useDispatch();

    useEffect(() => {
        let token = localStorage.getItem("token")
        if (token) dispatch(setUser(getUser(token)))
        else history.push("/login")
    }, [])

    return (
        <SidebarContext>
            <SidebarComponent />
            <div className={classes.mainBlock}>
                <HeaderComponent />
                <div className={classes.contentBlock}>
                    <PrivateRoutes />
                </div>
            </div>
        </SidebarContext>
    );
}

export default PrivateSection;
