import axios from "axios";
import { Time, Download } from "react-ionicons";
import { formatDateWithoutDay } from "../../utils";
import fileDownload from 'js-file-download'
import { getFile, getImage } from "../../api";
import "./style.css";

const MagazineCard = (props) => {
  const viewMagazine = () => {
    axios
      .get(getFile(props.magazineUrl), {
        responseType: "blob",
      })
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      })
      .catch((error) => console.log(error));
  };
  const downloadMagazine = () => {
    axios
      .get(getFile(props.magazineUrl), {
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, `${props.name}.pdf`)
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="magazine-card-container">
        <div className="magazine-card-item">
          <div className="magazine-card-info">
            <div
              className="magazine-card-media"
              onClick={() => {
                viewMagazine();
              }}
            >
              <img
                src={getImage(props.magazineThumbnail)}
                alt="magazineThumbnail"
                className="magazine-card-image"
              />
            </div>
            <div className="magazine-date-download">
              <div className="magazine-card-date">
                <Time
                  className="magazine-card-date-icon"
                  color={"#5e5e5e"}
                  height="20px"
                  width="20px"
                />
                <div className="magazine-card-date-text">
                  {formatDateWithoutDay(props.date)}
                </div>
              </div>
              <div
                className="magazine-card-download"
                onClick={() => {
                  downloadMagazine();
                }}
              >
                <Download color={"#5e5e5e"} height="20px" width="20px" />
                <div className="magazine-card-download-text">Download</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MagazineCard;
