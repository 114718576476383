import { useState } from 'react'
import { Form, Input, Button, message } from 'antd';
import avatar from "../../../resources/images/avatar.png";
import BarLoader from "react-spinners/BarLoader";
import { UserOutlined } from '@ant-design/icons';
import { forgotPassword } from "../../api"
import axios from 'axios';


const ForgotPassword = () => {

    const [loading, setLoading] = useState(false)
    const onFinish = (values) => {
        setLoading(true)
        let credentials = {
            email: values.email
        }
        axios.post(forgotPassword(), credentials).then(response => {
            setLoading(false)
            message.success("Reset Link has been sent to your email. Please Check.")
        }).catch(err => {
            setLoading(false)
            if (err.response.status == 404) message.error(err.response.data.message)
            else message.error("Unable to send Reset Link.")
        })
    };
    return (
        <div>
            <div className="login-container">
                <div className="login-form-container">
                    <img
                        width={100}
                        height={100}
                        src={avatar}
                        alt="avatar"
                        className="login-avatar"
                    />

                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                type="email"
                                placeholder="Email"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                {loading ? <BarLoader color="#fff" loading={true} height={8} width={100} /> : "Send Reset Link"}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>

    );
}

export default ForgotPassword;

