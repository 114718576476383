import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATH from '../resources/paths';
import LoadingComponent from '../components/DashBoard/loading';
const Dashboard = lazy(() => import('../components/DashBoard'));
const UserList = lazy(() => import('../components/DashBoard/UserDashboard/UserList'));
const ArticleList = lazy(() => import('../components/DashBoard/ArticlesDashboard/ArticleList'));
const ArticleAdd = lazy(() => import('../components/DashBoard/ArticlesDashboard/ArticleAdd'));
const ArticleUpdate = lazy(() => import('../components/DashBoard/ArticlesDashboard/ArticleUpdate'));
const FeaturedList = lazy(() => import('../components/DashBoard/FeaturedDashboard/FeaturedList'));
const DevotionAdd = lazy(() => import('../components/DashBoard/DevotionsDashboard/DevotionAdd'));
const DevotionUpdate = lazy(() => import('../components/DashBoard/DevotionsDashboard/DevotionUpdate'));
const DevotionList = lazy(() => import('../components/DashBoard/DevotionsDashboard/DevotionList'));
const DonationList = lazy(() => import('../components/DashBoard/DonationsDashboard/DonationList'));
const DonationCauseList = lazy(() => import('../components/DashBoard/DonationsDashboard/DonationCauseList'));
const DonationCauseAdd = lazy(() => import('../components/DashBoard/DonationsDashboard/DonationCauseAdd'));
const DonationCauseUpdate = lazy(() => import('../components/DashBoard/DonationsDashboard/DonationCauseUpdate'));
const ContributionAdd = lazy(() => import('../components/DashBoard/ContributionsDashboard/ContributionAdd'));
const ContributionUpdate = lazy(() => import('../components/DashBoard/ContributionsDashboard/ContributionUpdate'));
const ContributionList = lazy(() => import('../components/DashBoard/ContributionsDashboard/ContributionList'));
const AddressList = lazy(() => import('../components/DashBoard/SubscriptionsDashboard/AddressList'));
const EventList = lazy(() => import('../components/DashBoard/EventsDashboard/EventList'));
const SubscribersList = lazy(() => import('../components/DashBoard/SubscriptionsDashboard/SubscribersList'));
const MagazineList = lazy(() => import('../components/DashBoard/MagazinesDashboard/MagazineList'));
const AuthorsList = lazy(() => import('../components/DashBoard/AuthorsDashboard/AuthorsList'));
const MailingAddressList = lazy(() => import('../components/DashBoard/MailingsDashboard/MailingList'));
const Mails = lazy(() => import('../components/DashBoard/MailingsDashboard/Mails'));
const MailsCreateAndSend = lazy(() => import('../components/DashBoard/MailingsDashboard/MailsCreateAndSend'));


function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route exact path={PATH.dashboard} component={Dashboard} />
                <Route exact path={PATH.events} component={EventList} />
                <Route exact path={PATH.authors} component={AuthorsList} />
                <Route exact path={PATH.users} component={UserList} />
                <Route exact path={PATH.articles} component={ArticleList} />
                <Route exact path={PATH.addArticle} component={ArticleAdd} />
                <Route path={PATH.updateArticle} component={ArticleUpdate} />
                <Route exact path={PATH.devotions} component={DevotionList} />
                <Route exact path={PATH.featured} component={FeaturedList} />
                <Route exact path={PATH.addDevotion} component={DevotionAdd} />
                <Route path={PATH.updateDevotion} component={DevotionUpdate} />
                <Route exact path={PATH.contributions} component={ContributionList} />
                <Route exact path={PATH.addContribution} component={ContributionAdd} />
                <Route path={PATH.updateContribution} component={ContributionUpdate} />
                <Route exact path={PATH.magazines} component={MagazineList} />
                <Route exact path={PATH.donationHistory} component={DonationList} />
                <Route exact path={PATH.donationCause} component={DonationCauseList} />
                <Route exact path={PATH.addDonationCause} component={DonationCauseAdd} />
                <Route path={PATH.updateDonationCause} component={DonationCauseUpdate} />
                <Route exact path={PATH.mails} component={Mails} />
                <Route exact path={PATH.mailsCreateAndSend} component={MailsCreateAndSend} />
                <Route exact path={PATH.mailingAddress} component={MailingAddressList} />
                <Route exact path={PATH.settings} render={() => <div>settings</div>} />
                <Route exact path={PATH.address} component={AddressList} />
                <Route exact path={PATH.subscribers} component={SubscribersList} />
                <Route exact path={PATH.plans} render={() => <div>plans</div>} />
                <Route exact path={PATH.subscription} render={() => <div>subscription</div>} />
                <Redirect to={PATH.dashboard} />
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
