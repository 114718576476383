import './style.css'
import Img1 from "./image1.png"
import Img2 from "./image2.png"
import Img3 from "./image3.png"
import Img4 from "./image4.png"
import Img5 from "./image5.png"
const AboutSection = () => {
    return (
        <div className="home-about-wrapper">
            <div className="home-about-container">
                <div className="home-about-images">
                    <img src={Img1} alt="img1" className="home-about-image-1"></img>
                    <img src={Img2} alt="img2" className="home-about-image-2"></img>
                    <img src={Img3} alt="img3" className="home-about-image-3"></img>
                    <img src={Img4} alt="img4" className="home-about-image-4"></img>
                    <img src={Img5} alt="img5" className="home-about-image-5"></img>
                </div>
                <div className="home-about-content">
                    <p className="home-text-caption">ABOUT US</p>
                    <h1 className="home-text-main">A word about Good Shepherd Ministries</h1>
                    <p className="home-text-paragraph">The Good Shepherd ministries (Nallaedayn Ministries) is a movement formed in
                        New York, USA in 2001. The movement was started by the brethren who immigrated to the United States from
                        Kerala, the southernmost state of India.</p>
                    <a href="/about-us"><button className="home-section-button" >More About Us</button></a>
                </div>
            </div>
        </div>
    )
}

export default AboutSection