import { React, useState, useEffect } from 'react'
import AddressIcon from "../../resources/Icons/address.png"
import PhoneIcon from "../../resources/Icons/phone-call.png"
import EmailIcon from "../../resources/Icons/letter.png"
import FacebookIcon from "../../resources/Icons/facebook.png";
import AddIcon from "../../resources/Icons/add.svg"
import YoutubeIcon from "../../resources/Icons/youtube.png"
import './style.css'

const facebookPostsJson = {
    "Sunday message by Joby James": "https://www.facebook.com/atlantabrethren/videos/5726054127412479",
    "Tribute to Evg. Shalu T Ninan #shalutninan": "https://www.facebook.com/PSALMSRADIO/videos/3850777298309690",
    "And when I stand upon the other shore I’ll praise Him more and more Shalu & Moni - @swbc13": "https://www.facebook.com/southwest.brethren/videos/511762579837357"
}

const YoutubeVideosJson = {
    "20th Anniversary of Good Shepherd (Nalla Edayan) Ministries": "https://www.youtube.com/watch?v=QuiUhJ1u8Kc",
    "Jayaveeran Yeshuvin | ജയവീരൻ യേശുവിൻ | Mercy Samuel": "https://www.youtube.com/watch?v=ZrPo68qAoZk",
    "Thaanguvan Illene Shakthi | താങ്ങുവാനില്ലിനി ശക്തി | Mercy Samuel": "https://www.youtube.com/watch?v=0xsy__qlTiM"
}

const Footer = () => {
    const [facebookPosts, setFacebookPosts] = useState(facebookPostsJson);
    const [YoutubeVideos, setYoutubeVideos] = useState(YoutubeVideosJson)
    // this is for toggling footer in mobile view
    const [openedFooter, setOpenedFooter] = useState("");
    const classActive = "footer-item-contents activeFooter"
    const classInactive = "footer-item-contents inactiveFooter"
    const [facebookClass, setFacebookClass] = useState(classInactive)
    const [youtubeClass, setYoutubeClass] = useState(classInactive)

    useEffect(() => {
        if (openedFooter === "facebook") {
            setFacebookClass(classActive)
            setYoutubeClass(classInactive)
        }
        else if (openedFooter === "youtube") {
            setFacebookClass(classInactive)
            setYoutubeClass(classActive)
        }
        else {
            setFacebookClass(classInactive)
            setYoutubeClass(classInactive)
        }
    }, [openedFooter])
    return (
        <footer>
            <div className="footer-wrapper">
                <div className="footer-container">
                    <div className="footer-items-container footer-brand">
                        <div className="footer-item-header">
                            <h3>Good Shepherd Ministries</h3>
                        </div>
                        <div className="footer-item-contents">
                            <div>
                                <p className="footer-item-text">Connect with us</p>
                            </div>
                            <div>
                                <img className="footer-item-icon" alt="Address" src={AddressIcon} />
                                <p className="footer-item-text">Good Shepherd Ministries,
                                    P.O Box #661525, Bronx, New York, USA
                                </p>
                                <p className="footer-item-text">Mulampallil Buildings
                                    Kallissery P.O, Chengannur
                                    Alappuzha 689124</p>
                            </div>
                            <div>
                                <img className="footer-item-icon" alt="Call" src={PhoneIcon} />
                                <p className="footer-item-text">+1 (914) 450-4966</p>
                            </div>
                            <div>
                                <img className="footer-item-icon" alt="Email" src={EmailIcon} />
                                <p className="footer-item-text">nallaedayan@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="footer-items-container footer-brand">
                        <div className="footer-item-header">
                            <h3>Facebook Feed</h3>
                            {openedFooter !== "facebook" ?
                                <button className="footer-item-header-icon" onClick={() => setOpenedFooter("facebook")}>
                                    <img src={AddIcon} height="15px" width="15px" alt="add"></img>
                                </button> :
                                <button className="footer-item-header-icon" onClick={() => setOpenedFooter("")}>
                                    <img src={AddIcon} className="footer-item-header-icon-cross" height="15px" width="15px" alt="add"></img>
                                </button>}
                        </div>
                        <div className={facebookClass}>
                            {Object.keys(facebookPosts).map((key, index) => {
                                return (
                                    <div>
                                        <img className="footer-item-icon" alt="LinkIcon" src={FacebookIcon} />
                                        <a href={facebookPosts[key]} target="_blank" rel="noopener noreferrer"
                                            className="footer-item-text">{key}</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="footer-items-container footer-brand">
                        <div className="footer-item-header">
                            <h3>Youtube Videos</h3>
                            {openedFooter !== "youtube" ?
                                <button className="footer-item-header-icon" onClick={() => setOpenedFooter("youtube")}>
                                    <img src={AddIcon} height="15px" width="15px" alt="add"></img>
                                </button> :
                                <button className="footer-item-header-icon" onClick={() => setOpenedFooter("")}>
                                    <img src={AddIcon} className="footer-item-header-icon-cross" height="15px" width="15px" alt="add"></img>
                                </button>}
                        </div>
                        <div className={youtubeClass}>
                            {Object.keys(YoutubeVideos).map((key, index) => {
                                return (
                                    <div>
                                        <img className="footer-item-icon" alt="LinkIcon" src={YoutubeIcon} />
                                        <a href={YoutubeVideos[key]} target="_blank" rel="noopener noreferrer"
                                            className="footer-item-text">{key}</a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <p className="footer-credits">©  Good Shepherd Ministries. All Right Reserved 2021.</p>
        </footer>
    )
}


export default Footer;
