import { useState, useEffect } from "react";
import axios from "axios";
import coverImage from '../../../resources/images/gallery.jpg'
import PageLoading from "../../PageLoading";
import Pagination from "../../Pagination"
import { getGallery, getImage } from "../../api";
import { Image, message, Empty } from 'antd';
import "./style.css";

const headImage = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage})`,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover',
}


const GalleryPage = () => {
    const [gallery, setGallery] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(3)
    const [limit, setLimit] = useState(1)

    const incrimentPage = () => {
        setCurrentPage((page) => page + 1)
    }

    const decrementPage = () => {
        setCurrentPage((page) => page - 1)
    }

    const setPage = (page) => {
        setCurrentPage(page)
    }

    const fetchGallery = () => {
        setLoading(true)
        axios
            .get(getGallery(), { params: { pageNo: currentPage - 1, limit } })
            .then((gallery) => {
                setGallery(gallery.data.data.gallery);
                setTotalCount(gallery.data.data.totalCount)
                setLoading(false)

            })
            .catch((err) => {
                setLoading(false)
                message.error("Unable to load gallery images")
            });
    };

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
        fetchGallery()
    }, [currentPage]);

    return (
        <div>
            <PageLoading loading={loading} />
            <div className="gallery-page-container">
                <div className="gallery-banner">
                    <div className="gallery-img" style={headImage}>
                        <div className="gallery-banner-title-wrap">
                            <h1 className="gallery-banner-title">Gallery</h1>
                        </div>
                    </div>
                </div>
                {gallery.length == 0 ? <Empty
                    className="empty-icon"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Images Present</span>} />
                    : null}
                {gallery.map(sections => {
                    return (
                        <Image.PreviewGroup key={sections._id}>
                            <div className="gallery-sections-title">
                                <h2>{sections.title}</h2>
                            </div>
                            <div className="gallery-sections-container">
                                {sections.images.map(image => {
                                    return (
                                        <div key={image._id} className="gallery-image-item">
                                            <Image
                                                placeholder={true}
                                                src={getImage(image.path)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </Image.PreviewGroup>)
                })}
            </div>
            <Pagination
                goToNextPage={incrimentPage}
                goToPreviousPage={decrementPage}
                changePage={setPage}
                currentPage={currentPage}
                totalCount={totalCount}
                pageLimit={pageLimit}
                limit={limit}
            />
        </div>
    );
};

export default GalleryPage;
