import axios from 'axios'
import { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { getDefaultDevotion } from "../../api";
import "./style.css";
const DailyDevotion = () => {
    const [devotion, setDevotion] = useState(null)
    const fetchDevotion = () => {
        axios.get(getDefaultDevotion())
            .then(response => {
                setDevotion(response.data.data)
            }).catch(err => {
            })
    }

    useEffect(() => {
        fetchDevotion()
    })
    return (
        <div className="devotion-wrapper">
            <div className="devotion-container">
                {devotion ?
                    <>
                        <div className="devotion-header-wrapper">
                            <p className="home-text-caption">Daily Devotion</p>
                            <div className="devotion-header">
                                <p className="home-text-main text-center">{devotion.title}</p>
                                <p className="home-text-paragraph">Author :
                                    <span style={{ fontStyle: "italic" }}> {devotion.author}</span>
                                </p>
                            </div>
                        </div>
                        <div className="devotion-content">
                            <p className="home-text-paragraph text-justify">{ReactHtmlParser(devotion.content)}</p>
                        </div>
                    </> : null
                }
            </div>
        </div>
    );
};

export default DailyDevotion;
