exports.formatDate = (date) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

exports.formatDateWithoutDay = (date) => {
  const options = {
    year: "numeric",
    month: "long",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

exports.formatDateWithoutYear = (date) => {
  const options = {
    month: "long",
    day: "2-digit",
  };
  return new Date(date).toLocaleDateString("en-US", options).toUpperCase();
};

exports.eventFormat = (date) => {
  const options = {
    weekday: "long",
    month: "long",
    day: "2-digit",
    year: "numeric",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};
exports.eventDayTime = (date) => {
  const options = {
    weekday: "long",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};
exports.getYear = (date) => {
  const options = {
    year: "numeric",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

exports.eventDateFormat = (date) => {
  return {
    day: new Date(date).toLocaleDateString("en-US", { day: "2-digit" }),
    month: new Date(date).toLocaleDateString("en-US", { month: "short" })
  };
};

exports.toLower = (value) => {
  return value.toLowerCase()
}

exports.type = Object.freeze({
  ALL: "all",
  EXPIRED: "expired",
  ACTIVE: "active"
})
exports.userType = Object.freeze({
  ADMIN: "ADMIN",
  EDITOR: "EDITOR",
  USER: "USER"
})

exports.loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

