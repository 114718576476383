import './style.css'
import PhoneIcon from "../../resources/Icons/phone-call.png"
import AddressIcon from '../../resources/Icons/address.png'
import EmailIcon from "../../resources/Icons/letter.png"
import { useState } from 'react'
import { message as toast } from 'antd'
import axios from 'axios'
import {
    sendContactData
} from '../../components/api'

const ContactUs = (props) => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const handleNameChange = (e) => {
        e.preventDefault();
        setName(e.target.value)
    }

    const handleEmailChange = (e) => {
        e.preventDefault();
        setEmail(e.target.value)
    }

    const handleMessageChange = (e) => {
        e.preventDefault();
        setMessage(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            name: name,
            email: email,
            message: message
        }
        axios
            .post(sendContactData(), data)
            .then((response) => {
                toast.success("Your response has been successfuly submitted.")
            })
            .catch((err) => {
                toast.error("Unable to send your response.")
            })
    }
    return (
        <div className="contact-us-page">
            {props.hide ? null : <div className="contact-us-banner">
                <h1 className="contact-us-title">Contact Us</h1>
            </div>}
            <div className="map-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24128.610250727237!2d-73.86005915000474!3d40.89214156781918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f32ab758d555%3A0x3a3906f6673ead55!2sBronx%2C%20NY%2010466%2C%20USA!5e0!3m2!1sen!2sin!4v1622913029553!5m2!1sen!2sin" width="100%" height="800" style={{ border: 0 }, { opacity: 0.6 }} allowfullscreen="" loading="lazy"></iframe>
                <div className="contactUsContent">
                    <div className="contactDetails">
                        <h1>How To Find Us</h1>
                        <div className="contactDetails-content">
                            <img className="contactDetails-icon" alt="Address" src={AddressIcon} />
                            <p className="contactDetails-text">
                                Good Shepherd Ministries,<br></br>
                                P.O Box #661525,<br></br>
                                Bronx, New York, USA
                            </p>
                            <p className="contactDetails-text">
                                Mulampallil Buildings<br />
                                Kallissery P.O, Chengannur<br />
                                Alappuzha, Kerala 689124
                            </p>
                        </div>
                        <div className="contactDetails-content">
                            <img className="contactDetails-icon" alt="Call" src={PhoneIcon} />
                            <p className="contactDetails-text"> <a href="tel:+1 (914) 450-4966">+1 (914) 450-4966</a>,<a href="tel:+91 9447359183">+91 9447359183</a></p>
                        </div>
                        <div className="contactDetails-content">
                            <img className="contactDetails-icon" alt="Email" src={EmailIcon} />
                            <a href="mailto:nallaedayan@gmail.com"><p className="contactDetails-text">nallaedayan@gmail.com</p></a>
                        </div>
                    </div>
                    <div className="contactForm">
                        <form className='react-form' onSubmit={handleSubmit}>
                            <h2>Contact Us</h2>
                            <div className="formData">
                                <fieldset className='form-group'>
                                    <input id='formName' className='form-input' name='name' type='text' required placeholder="Your Name" onChange={handleNameChange} value={name} />
                                </fieldset>

                                <fieldset className='form-group'>
                                    <input id='formEmail' className='form-input' name='email' type='email' required placeholder="Email" onChange={handleEmailChange} value={email} />
                                </fieldset>
                            </div>
                            <fieldset className='form-group'>
                                <input id='formMessage' className='form-input' name='message' type='text' required placeholder="Your Message" onChange={handleMessageChange} value={message} />
                            </fieldset>

                            <div className='form-group'>
                                <input id='formButton' className='button' type='submit' />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;