import { Button } from "antd";
import styled from 'styled-components'


const ButtonContainer = styled.span`
  .ant-btn-primary {
    background: #fc8e44;
    border-color: #fc8e44;
  }
  &:hover {
      .ant-btn-primary {
          background: #fff;
          color:#fc8e44;
          border-color: #fc8e44;
        }
`;

const ButtonOutline = (props) => {
  return (
    <ButtonContainer>
      <Button type="primary" onClick={props.onClick} size={props.size}>
        {props.text}
      </Button>
    </ButtonContainer>
  );
};

export default ButtonOutline;
