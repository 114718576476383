import { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../../Pagination"
import ArticleCard from "../ArticleCard";
import PageLoading from "../../PageLoading";
import { Empty, message } from 'antd';
import coverImage from '../../../resources/images/article.jpg'
import { getArticles } from "../../api";
import "./style.css";


const headImage = {
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage})`,
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: 'cover',
}
const ArticlesPage = (props) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(3)
  const [limit, setLimit] = useState(9)

  const incrimentPage = () => {
    setCurrentPage((page) => page + 1)
  }

  const decrementPage = () => {
    setCurrentPage((page) => page - 1)
  }

  const setPage = (page) => {
    setCurrentPage(page)
  }
  const fetchArticles = () => {
    setLoading(true)
    axios
      .get(getArticles(), { params: { pageNo: currentPage - 1, limit } })
      .then((articles) => {
        setArticles(articles.data.data.articles);
        setTotalCount(articles.data.data.totalCount)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        message.error("Unable to load Articles")
      });
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    fetchArticles()
  }, [currentPage]);

  return (
    <div>
      <PageLoading loading={loading} />
      <div className="articles-banner">
        <div className="articles-img" style={headImage}>
          <div className="articles-banner-title-wrap">
            <h1 className="articles-banner-title">Articles</h1>
          </div>
        </div>
      </div>

      {articles.length == 0 ? <Empty className="empty-icon" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
        <span>
          No Articles Present
              </span>
      } /> : null}
      <div className="articles-cards-wrapper">
        <div className="articles-cards-container">
          <div className="articles-cards-row">
            <div className="articles-cards-col">
              <div className="articles-cards-inner">
                {articles.map((article) => (
                  <ArticleCard key={article._id} {...{ ...article, ...props.match }} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        goToNextPage={incrimentPage}
        goToPreviousPage={decrementPage}
        changePage={setPage}
        currentPage={currentPage}
        totalCount={totalCount}
        pageLimit={pageLimit}
        limit={limit}
      />
    </div>
  );
};

export default ArticlesPage;
