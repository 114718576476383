import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Row, Col } from 'antd'
import { Image, message } from 'antd';
import axios from "axios";
import PageLoading from "../PageLoading";
import fileDownload from 'js-file-download'
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { getImage, getFeaturedId, getFile } from "../api";
import "./style.css";

const Featured = () => {
    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [featured, setFeatured] = useState({});

    const fetchFeatured = () => {
        setLoading(true)
        axios.get(getFeaturedId(id)).then((featured) => {
            setFeatured(featured.data.data);
            setLoading(false)

        }).catch(err => {
            setLoading(false)
            if (err.response.status === 404) history.push("/404")
            else message.error("Unable to load Featured details")

        });
    };

    const viewMagazine = (featured) => {
        axios
            .get(getFile(featured.pdf), {
                responseType: "blob",
            })
            .then((response) => {
                const file = new Blob([response.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            })
            .catch((error) => console.log(error));
    };

    const downloadPDF = (featured) => {
        axios
            .get(getFile(featured.pdf), {
                responseType: "blob",
            })
            .then((response) => {
                fileDownload(response.data, `${featured.title}.pdf`)
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        fetchFeatured();
    }, []);

    return <>
        <PageLoading loading={loading} />
        {Object.keys(featured).length ? (
            <div className="featured-page-main-container">
                <div className="featured-page-container">
                    <div className="featured-page-info">
                        <div className="featured-page-head">
                            <h1 className="featured-page-title">{featured.title}</h1>
                        </div>
                        <div className="featured-page-image-container">
                            <Image placeholder={true} src={getImage(featured.mobileImage)} />
                        </div>
                        <div className="featured-page-card-flex">
                            <Row gutter={24}>
                                <Col xs={10} sm={6} md={4} lg={4} xl={4}>
                                    {featured.pdf ? <div>
                                        <Button icon={<DownloadOutlined />} size="large" type="primary" onClick={() => { downloadPDF(featured) }}>Download</Button>
                                    </div> : null}
                                </Col>
                                <Col xs={10} sm={6} md={4} lg={4} xl={4}>
                                    {featured.pdf ? <div>
                                        <Button icon={<EyeOutlined />} size="large" onClick={() => { viewMagazine(featured) }}>View</Button>
                                    </div> : null}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        ) : null}
    </>
};

export default Featured;
