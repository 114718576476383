import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { unsubscribe } from "../../api";
import "./style.css";

const UnsubscribePage = (props) => {
  const [message, setMessage] = useState("");
  const { id } = useParams();

  const unsubscribeMagazine = () => {
    axios
      .delete(unsubscribe(id))
      .then((response) => {
        setMessage(response.data.message);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    unsubscribeMagazine();
  }, []);

  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-box">
        <div className="unsubscribe-title">
          <p>UNSUBSCRIPTION</p>
        </div>
        <div className="unsubscribe-text">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribePage;
