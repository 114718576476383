import './style.css'
import MissionAndVision from "./MissionAndVision/index"
import MovingForward from "./MovingForward/index"
import Contributions from "./Contributions/index"
import ContactUs from "../ContactUs"
import { BackTop } from 'antd';
const AboutUsPage = (props) => {
    return (
        <div className="about-us-page">
            <div className="about-us-banner">
                <div className="about-us-banner-title-wrap">
                    <h1 className="about-us-banner-title">About Us</h1>
                </div>
            </div>
            <section className="about-us-history" id="history">
                <div className="history-container">
                    <div className="history-image"></div>
                    <div className="history-content">
                        <p className="section-caption">HOW IT ALL STARTED</p>
                        <h2 className="section-header">Our History</h2>
                        <div className="history-text">
                            <p className="section-text">
                                The Good Shepherd ministries (Nallaedayn Ministries) is a movement formed in New York, USA in 2001. The movement was started by the brethren who immigrated to the United States from Kerala, the southernmost state of India. This ministry has continued unabated for more than twenty years. This ministry is registered in Kerala and is in progress by 2020. To do many Charitable deeds throughout India specially Kerala and Tamilnadu through the ministry for over twenty years. Headquartered in New York, the ministry is supported by families in the Brethren assemblies and well wishers.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <MissionAndVision />
            <MovingForward />
            <Contributions />
            <ContactUs hide={true} />
            <BackTop />
        </div>
    )
}

export default AboutUsPage;