import React from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from '../hooks/useWindowSize';
import PrivateSection from './PrivateSection';
import PublicRoutes from './PublicRoutes';
import { Route, Switch } from 'react-router-dom';
import LoginPage from "../components/Login/LoginPage"
import ForgotPassword from "../components/Login/ForgotPassword"
import ResetPassword from "../components/Login/ResetPassword"

function Routes() {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();

    return (
        <>
            <Switch>
                <Route path="/login" exact component={LoginPage} />
                <Route path="/forgot-password" exact component={ForgotPassword} />
                <Route path="/reset-password/:resetToken" exact component={ResetPassword} />
                <Route path="/dashboard" component={PrivateSection} />
                <Route path="/" component={PublicRoutes} />
            </Switch>

        </>
    )
}

export default Routes;

