import { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import axios from 'axios'
import {
	getSubscriptionPlans,
	getPricing,
	subscriptionCheckout,
	getCountries,
	getRazorpayKey,
	getBaseUrl
} from '../../api'
import { loadScript } from '../../utils'
import PageLoading from "../../PageLoading";
import coverImage from "../../../resources/images/subscription.jpg";
import { Form, Input, Row, Col, Button, Radio, Select, message as toast } from 'antd'
import { useStripe } from '@stripe/react-stripe-js'
import { toLower } from "../../utils"
import './style.css'
const { TextArea } = Input
const { Option } = Select;

const headImage = {
	background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage}) no-repeat center`,
	backgroundSize: 'cover',
}

const SubscriptionPage = (props) => {
	const history = useHistory();
	const [activeStep, setActiveStep] = useState(0)
	const [loading, setLoading] = useState(true);
	const [plans, setPlans] = useState([])
	const [addressDetails, setAddressDetails] = useState({})
	const [priceDetails, setPriceDetails] = useState({})
	const [planId, setPlanId] = useState()
	const [planName, setPlanName] = useState('')
	const [message, setMessage] = useState('')
	const [countries, setCountries] = useState([])
	const [addressForm] = Form.useForm()
	const stripe = useStripe()

	const subscriptionPlans = () => {
		axios
			.get(getSubscriptionPlans())
			.then((plans) => {
				setPlans(plans.data.data)
			})
			.catch((err) => {
				toast.error("Unable to load Subscription plans")
			})
	}

	const fetchCountries = () => {
		setLoading(true)
		axios
			.get(getCountries())
			.then((countries) => {
				setLoading(false)
				setCountries(countries.data.data)
			})
			.catch((err) => {
				setLoading(false)
				toast.error("Unable to load countries")
			})
	}

	const onAddressSubmit = (values) => {
		setAddressDetails(values)
		setActiveStep((step) => step + 1)
		setPriceDetails({})
		setPlanId(null)
		setPlanName('')
	}

	const onPlanSubmit = () => {
		if (planId) {
			setActiveStep((step) => step + 1)
		} else {
			setMessage('Please choose a plan')
		}
	}

	const prevStep = () => {
		setActiveStep((step) => step - 1)
	}

	const fetchAmount = (planId) => {
		let country = addressDetails.country
		if (planId && country) {
			axios.get(getPricing(planId, country)).then((response) => {
				setPriceDetails(response.data.data)
			}).catch((err) => {
				toast.error("Unable to load Pricing Details")
			})
		}
	}

	const createCheckoutSession = () => {
		setLoading(true)
		const data = {
			addressDetails,
			priceDetails,
		}
		axios
			.post(subscriptionCheckout(), data)
			.then(async (response) => {
				setLoading(false)
				const { paymentGateway, sessionId, addressDetails } = response.data.data
				if (paymentGateway === 'stripe') {
					const { error } = await stripe.redirectToCheckout({ sessionId })
					if (error) console.log(error)
				} else if (paymentGateway === 'razorpay') {
					const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
					console.log(res)
					if (!res) {
						message.error('Razorpay SDK failed to load. Are you online?')
						return
					}

					const options = {
						key: getRazorpayKey(),
						currency: addressDetails.currency,
						amount: addressDetails.amount,
						order_id: sessionId,
						name: 'Good Sheperd Ministries Subscription',
						description: `Good Shepherd Magazine ${planName} Subscription`,
						image: `${getBaseUrl()}assets/images/logo.jpg`,
						handler: function (response) {
							console.log(response)
							if (!(typeof response.razorpay_payment_id == 'undefined' || response.razorpay_payment_id < 1)) {
								history.push(`/subscription/success/${response.razorpay_payment_id}`)
							}
						},
						prefill: {
							name: `${addressDetails.firstName} ${addressDetails.lastName}`,
							email: addressDetails.email,
							phone_number: addressDetails.phoneNumber
						}
					}
					const paymentObject = new window.Razorpay(options)
					paymentObject.on('payment.failed', function (response) {
						history.push(`/subscription/canceled`)
					});
					paymentObject.open()
				}
			})
			.catch((error) => {
				setLoading(false)
				toast.error("An error occured while making Payment. Try Again")
			})
	}

	useEffect(() => {
		subscriptionPlans();
		fetchCountries();
	}, [])

	const displayForms = () => {
		if (activeStep === 0) {
			return (
				<Form
					size="large"
					layout={"vertical"}
					form={addressForm}
					name="addressForm"
					onFinish={onAddressSubmit}
					scrollToFirstError
					className="subscription-form"
				>
					<h2 className="subscription-form-sub-title">Address Info</h2>

					<Row gutter={24}>
						<Col xs={24} sm={24} md={12} lg={8} xl={8}>
							<Form.Item
								name="firstName"
								label="First Name"
								rules={[
									{
										required: true,
										message: 'Please input your first name',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8}>
							<Form.Item
								name="lastName"
								label="Last Name"
								rules={[
									{
										required: true,
										message: 'Please input your last name',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={12} lg={8} xl={8}>
							<Form.Item
								name="email"
								label="E-mail"
								rules={[
									{
										type: 'email',
										message: 'The input is not valid E-mail!',
									},
									{
										required: true,
										message: 'Please input your E-mail!',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8}>
							<Form.Item
								label="Phone Number"
								name="phoneNumber"
								rules={[
									{
										required: true,
										message: 'Please input your phone number!',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col xs={24} sm={24} md={24} lg={16} xl={16}>

							<Form.Item
								name="address"
								label="Address"
								rules={[
									{
										required: true,
										message: 'Please input Address',
									},
								]}
							>
								<TextArea />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={12} lg={8} xl={8}>
							<Form.Item
								name="state"
								label="State"
								rules={[
									{
										required: true,
										message: 'Please input state',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={8} xl={8}>
							<Form.Item
								name="country"
								label="Country"
								rules={[
									{
										required: true,
										message: 'Please input country',
									},
								]}
							>
								<Select
									showSearch
									placeholder="Select Country"
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.label !== null
										&& toLower(option.props.label).includes(toLower(input))
									}
								>
									{countries.map(item => <Option
										label={item.name}
										key={item._id}
										value={item._id}
									>{item.name}</Option>)}

								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={24}>
						<Col xs={24} sm={24} md={12} lg={8} xl={8}>
							<Form.Item
								name="pincode"
								label="Pincode"
								rules={[
									{
										required: true,
										message: 'Please input pincode',
									},
								]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Form.Item >
						<Button type="primary" htmlType="submit">
							Next
						</Button>
					</Form.Item>
				</Form>
			)
		} else if (activeStep === 1) {
			return (
				<>
					<h2 className="subscription-form-sub-title">Subsciption Plan</h2>
					<div className="subscription-plan-form">
						<p>Choose your plan </p>
						<Radio.Group value={planId}>
							{plans.map((plan) => (
								<Radio
									key={plan._id}
									value={plan._id}
									onClick={() => {
										setMessage('')
										setPlanId(plan._id)
										setPlanName(plan.name)
										fetchAmount(plan._id)
									}}
								>
									{plan.name}
								</Radio>
							))}
						</Radio.Group>
						<p className="subscription-plan-warning">{message}</p>
					</div>

					<h2 className="subscription-plan-total">
						Total amount:&nbsp;{priceDetails.amount}&nbsp;
						{priceDetails.currency ? (priceDetails.currency.label ? priceDetails.currency.label : '') : ""}
					</h2>

					<Row gutter={8}>
						<Col span={4}>
							<Button type="primary" onClick={prevStep}>Prev</Button>
						</Col>
						<Col span={4}>
							<Button type="primary" onClick={onPlanSubmit} >Next</Button>
						</Col>
					</Row>
				</>
			)
		} else if (activeStep === 2) {
			return (
				<>
					<h2 className="subscription-form-sub-title">Payment</h2>
					<h2 className="subscription-plan-summary">
						Summary:&nbsp;{planName}&nbsp;subscription
					</h2>
					<h2 className="subscription-plan-total">
						Total amount:&nbsp;{priceDetails.amount}&nbsp;
						{priceDetails.currency.label}
					</h2>
					<Button type="primary" onClick={createCheckoutSession}>Go to Payment</Button>
				</>
			)
		}
	}

	return (
		<>
			<PageLoading loading={loading} />
			<div className="subscription-page-container">
				<div className="subscription-banner">
					<div className="subscription-img" style={headImage}>
						<div className="subscription-banner-title-wrap">
							<h1 className="subscription-banner-title">Subscription</h1>
						</div>
					</div>
				</div>
				<div className="subscription-container subscription-gen-container">
					<div className="subscription-form-container">
						<h1 className="subscription-form-title">
							Magazine Subscription Form
						</h1>
						<div className="subscription-form-wrap">{displayForms()}</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SubscriptionPage
