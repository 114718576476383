import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Row, Col } from 'antd'
import { Image, message } from 'antd';
import axios from "axios";
import PageLoading from "../../PageLoading";
import { getImage, getEvent } from "../../api";
import { Time, InformationCircleOutline, LocationOutline } from "react-ionicons";
import { eventFormat } from "../../utils";
import {
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from "react-share";
import { FacebookIcon, WhatsappIcon, TelegramIcon } from "react-share";
import "./style.css";

const Event = () => {
    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState({});

    const fetchEvent = () => {
        setLoading(true)
        axios.get(getEvent(id)).then((event) => {
            setEvent(event.data.data);
            setLoading(false)

        }).catch(err => {
            setLoading(false)
            if (err.response.status === 404) history.push("/404")
            else message.error("Unable to load Event details")

        });
    };

    useEffect(() => {
        fetchEvent();
    }, []);

    return <>
        <PageLoading loading={loading} />
        {Object.keys(event).length ? (
            <div className="event-main-container">
                <div className="event-container">
                    <div className="event-info">
                        <div className="event-head">
                            <h1 className="event-title">{event.title}</h1>
                        </div>
                        <div className="event-card-flex">
                            <Time
                                className="event-icon"
                                color={"#fc8e44"}
                                height="20px"
                                width="20px"
                            />
                            <div className="event-card-flex-item">
                                {eventFormat(event.fromDate) === eventFormat(event.toDate) ?
                                    <>
                                        <p>Date: &nbsp;{eventFormat(event.fromDate)}</p>
                                    </>
                                    :
                                    <>
                                        <p>From:&nbsp;{eventFormat(event.fromDate)}
                                            <br />
                                            To:&nbsp;{eventFormat(event.toDate)}</p>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="event-card-flex">
                            <LocationOutline
                                className="event-icon"
                                color={"#fc8e44"}
                                height="20px"
                                width="20px"
                            />
                            <div className="event-card-flex-item">
                                <p>Event Type:&nbsp; {event.type}</p>
                                {event.location ? <p>Event Location:&nbsp; {event.location}</p> : null}
                            </div>
                        </div>
                        <div className="event-card-flex">
                            <InformationCircleOutline
                                className="event-icon"
                                color={"#fc8e44"}
                                height="20px"
                                width="20px"
                            />
                            <div className="event-description">
                                <p>{event.description}</p>
                                <Row gutter={24}>
                                    <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                                        {event.registerUrl ? <div>
                                            <Button size="large" type="primary"><a href={event.registerUrl}>REGISTER</a></Button>
                                        </div> : null}

                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                                        {event.eventUrl ? <div >
                                            <Button size="large"><a href={event.eventUrl}>JOIN US HERE</a></Button>
                                        </div> : null}

                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className="event-social-share">
                            <div className="share-button">
                                <WhatsappShareButton
                                    url={`${window.location.href}`}
                                    quote={`${event.title}`}
                                >
                                    <WhatsappIcon logoFillColor="white" round={true} size={30} />
                                </WhatsappShareButton>
                            </div>
                            <div className="share-button">
                                <FacebookShareButton
                                    url={`${window.location.href}`}
                                    quote={`${event.title}`}
                                >
                                    <FacebookIcon logoFillColor="white" round={true} size={30} />
                                </FacebookShareButton>
                            </div>
                            <div className="share-button">
                                <TelegramShareButton
                                    url={`${window.location.href}`}
                                    quote={`${event.title}`}
                                >
                                    <TelegramIcon logoFillColor="white" round={true} size={30} />
                                </TelegramShareButton>
                            </div>
                        </div>
                    </div>
                    <div className="event-image">
                        <Image placeholder={true} src={getImage(event.image)} />
                    </div>
                </div>
            </div>
        ) : null}
    </>
};

export default Event;
