import { useLocation } from "react-router-dom";
import logo from "../../../resources/images/logo.png";
import Menu from "../menu.svg"
import Cross from "../cross.png"
import "./style.css";
import ButtonOutline from '../../Buttons/ButtonOutline'
import { useState } from "react";


const NavMobile = (props) => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false)
  const [openedItem, setOpenedItem] = useState("")
  const highlight = (page) => {
    if (page == location.pathname || (location.pathname.includes(page) && page !== "/")) return "active";
    else return "inactive";
  };
  return (
    <header className={toggle ? "navbar-mobile-container-open" : "navbar-mobile-container-closed"}>
      <div className="navbar-mobile-container">
        <div className="navbar-mobile-logo-toggle">
          <div className="navbar-mobile-logo">
            <a href="/">
              <img
                src={logo}
                alt="logo"
                className="navbar-mobile-logo-image"
              />
            </a>
          </div>
          <button className="navbar-mobile-toggle-button" onClick={() => setToggle(prevToggle => !prevToggle)}>
            {!toggle ? <img src={Menu} alt="menu"></img> : <img src={Cross} alt="cross"></img>}
          </button>
        </div>
        {toggle &&
          <nav>
            <div className="navbar-mobile-item">
              <div className="navbar-mobile-main-item">
                <a href="/" className={highlight("/")}>Home</a>
              </div>
            </div>
            <div className="navbar-mobile-main-item">
              <div className="navbar-mobile-item">
                <a href="/about-us" className={highlight("/about-us")}>About Us</a>
                {openedItem !== "/aboutUs" ? <button onClick={() => setOpenedItem("/aboutUs")}><i class="fa fa-angle-down"></i></button> :
                  <button onClick={() => setOpenedItem("")}><i class="fa fa-angle-up"></i></button>}
              </div>
              {openedItem === "/aboutUs" &&
                <div className="navbar-mobile-sub-item">
                  <div className="navbar-mobile-item">
                    <a href="/about-us#history" className={highlight("/history")}>History</a>
                  </div>
                  <div className="navbar-mobile-item">
                    <a href="/about-us/statement-of-faith" className={highlight("/about-us/statement-of-faith")}>Statement Of Faith</a>
                  </div>
                  <div className="navbar-mobile-item">
                    <a href="/about-us#mission" className={highlight("/vision")}>Vision</a>
                  </div>
                  <div className="navbar-mobile-item">
                    <a href="about-us#contributions" className={highlight("/our-contributions")}>Contributions</a>
                  </div>
                </div>}
            </div>
            <div className="navbar-mobile-main-item">
              <div className="navbar-mobile-item">
                <a href="/article" className={highlight("/article")}>Articles</a>
                {openedItem !== "/article" ? <button onClick={() => setOpenedItem("/article")}><i class="fa fa-angle-down"></i></button> :
                  <button onClick={() => setOpenedItem("")}><i class="fa fa-angle-up"></i></button>}
              </div>
              {openedItem === "/article" &&
                <div className="navbar-mobile-sub-item">
                  <div className="navbar-mobile-item">
                    <a href="/article/family" className={highlight("/family")}>Family</a>
                  </div>
                  <div className="navbar-mobile-item">
                    <a href="/article/youth" className={highlight("/youth")}>Youth</a>
                  </div>
                  <div className="navbar-mobile-item">
                    <a href="/article/children" className={highlight("/children")}>Children</a>
                  </div>
                </div>}
            </div>
            <div className="navbar-mobile-main-item">
              <div className="navbar-mobile-item">
                <a href="/magazine" className={highlight("/magazine")}>Magazines</a>
                {/* {openedItem !== "/magazine" ? <button onClick={() => setOpenedItem("/magazine")}><i class="fa fa-angle-down"></i></button> :
                  <button onClick={() => setOpenedItem("")}><i class="fa fa-angle-up"></i></button>} */}
              </div>
            </div>
            <div className="navbar-mobile-main-item">
              <div className="navbar-mobile-item">
                <a href="/event" className={highlight("/event")}>Event</a>
                {/* {openedItem !== "/event" ? <button onClick={() => setOpenedItem("/event")}><i class="fa fa-angle-down"></i></button> :
                  <button onClick={() => setOpenedItem("")}><i class="fa fa-angle-up"></i></button>} */}
              </div>
            </div>
            <div className="navbar-mobile-main-item">
              <div className="navbar-mobile-item">
                <a href="/subscription" className={highlight("/subscription")}>Subscription</a>
                {/* {openedItem !== "/subscription" ? <button onClick={() => setOpenedItem("/subscription")}><i class="fa fa-angle-down"></i></button> :
                  <button onClick={() => setOpenedItem("")}><i class="fa fa-angle-up"></i></button>} */}
              </div>
            </div>
            <div className="navbar-mobile-main-item">
              <div className="navbar-mobile-item">
                <a href="/gallery" className={highlight("/gallery")}>Gallery</a>
                {/* {openedItem !== "/gallery" ? <button onClick={() => setOpenedItem("/gallery")}><i class="fa fa-angle-down"></i></button> :
                  <button onClick={() => setOpenedItem("")}><i class="fa fa-angle-up"></i></button>} */}
              </div>
            </div>
            <div className="navbar-mobile-main-item">
              <div className="navbar-mobile-item">
                <a href="/contact-us" className={highlight("/contact-us")}>Contact</a>
                {/* {openedItem!=="/contact-us"?<button onClick={()=>setOpenedItem("/contact-us")}><i class="fa fa-angle-down"></i></button>:
                    <button onClick={()=>setOpenedItem("")}><i class="fa fa-angle-up"></i></button>} */}
              </div>
            </div>
            <div className="navbar-mobile-donate">
              <a href="/donation">
                <ButtonOutline
                  text="DONATE"
                  size="large"
                /></a>
            </div>
          </nav>}
      </div>
    </header>
  )
}
export default NavMobile;