import { useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../../resources/images/logo.png";
import "./style.css";
const NavDesktop = () => {
  const [colorChange, setColorchange] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) setColorchange(true);
    else setColorchange(false);
  };

  window.addEventListener('scroll', changeNavbarColor);
  const highlight = (page) => {
    if (page == location.pathname || (location.pathname.includes(page) && page !== "/")) return "active";
    else {
      if (!home() || (home() && colorChange)) return "inactive"
      else return "inactive-home";
    }
  };

  const home = () => {
    if (location.pathname == "/") return true
    else return false;
  }

  return (
    <header className={`navbar-header ${home() ? "sticky" : ""} ${(home() && !colorChange) ? "" : "color-change "}`}>
      <div className="navbar-container" >
        <div className="navbar-main">
          <div className="navbar-logo">
            <a href="/">
              <img
                width={80}
                height={80}
                src={logo}
                alt="logo"
                className="navbar-logo-image"
              />
            </a>
          </div>
          <nav className="navbar-menu-container">
            <ul className="navbar-menu-box">
              <li className="navbar-menu-item">
                <a className={`${highlight("/")}`} href="/">
                  Home
                </a>{" "}
              </li>
              <li className="navbar-menu-item">
                <a className={`${highlight("/about-us")}`} href="/about-us">
                  About
                </a>
                <span></span>
                <ul className="navbar-sub-menu">
                  <li className="sub-menu-item">
                    <a href="/about-us#history">
                      <span className="sub-menu-bullet">+&nbsp;</span> History
                    </a>
                  </li>
                  <li className="sub-menu-item">
                    <a href="/about-us/statement-of-faith">
                      <span className="sub-menu-bullet">+&nbsp;</span> Statement
                      of Faith
                    </a>
                  </li>
                  <li className="sub-menu-item">
                    <a href="/about-us#mission" >
                      <span className="sub-menu-bullet">+&nbsp;</span> Vision
                    </a>
                  </li>
                  <li className="sub-menu-item">
                    <a href="/about-us#contributions">
                      <span className="sub-menu-bullet">+&nbsp;</span> Contributions
                    </a>
                  </li>
                </ul>
              </li>
              <li className="navbar-menu-item">
                <a className={`${highlight("/article")}`} href="/article">
                  Articles
                </a>
                <span></span>
                <ul className="navbar-sub-menu">
                  <li className="sub-menu-item">
                    <a href="/article">
                      <span className="sub-menu-bullet">+&nbsp;</span> Family
                    </a>
                  </li>
                  <li className="sub-menu-item">
                    <a href="/article">
                      <span className="sub-menu-bullet">+&nbsp;</span> Youth
                    </a>
                  </li>
                  <li className="sub-menu-item">
                    <a href="/article">
                      <span className="sub-menu-bullet">+&nbsp;</span> Children
                    </a>
                  </li>
                </ul>
              </li>
              <li className="navbar-menu-item">
                <a className={`${highlight("/magazine")}`} href="/magazine">
                  Magazines
                </a>{" "}
              </li>
              <li className="navbar-menu-item">
                <a className={`${highlight("/event")}`} href="/event">
                  Events
                </a>{" "}
              </li>

              <li className="navbar-menu-item">
                <a className={`${highlight("/subscription")}`} href="/subscription">
                  Subscription
                </a>{" "}
              </li>

              <li className="navbar-menu-item">
                <a className={`${highlight("/gallery")}`} href="/gallery">
                  Gallery
                </a>{" "}
              </li>
              <li className="navbar-menu-item">
                <a className={`${highlight("/contact-us")}`} href="/contact-us">
                  Contact
                </a>{" "}
              </li>
            </ul>
          </nav>
          <div className="navbar-donate-container">
            <div className={`navbar-donate ${(home() && !colorChange) ? "navbar-donate-scroll" : "navbar-donate-default"}`}
              onClick={() => { history.push("/donation"); }}>
              <a >DONATE</a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavDesktop;
