import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import EventCard from "../EventCard";
import "./style.css";

const EventContainer = (props) => {
    const history = useHistory();
    const [events, setEvents] = useState([]);
    const [hasOverFlow, setHasOverflow] = useState(false);
    const container = useRef(null);
    let right = "true"
    const checkForOverflow = () => {
        const { scrollWidth, clientWidth } = container.current;
        const overflow = scrollWidth > clientWidth;
        setHasOverflow(overflow);
    };

    const checkForScrollPosition = () => {
        const { scrollLeft, scrollWidth, clientWidth } = container.current;
        if (scrollLeft + 5 <= scrollWidth - clientWidth && right) {
            scrollContainerBy(300);
        } else if (scrollLeft > 0) {
            scrollContainerBy(-300);
            right = false;
        }
        else {
            right = true;
        }
    };

    const scrollContainerBy = (distance) => {
        container.current.scrollBy({ left: distance, behavior: "smooth" });
    };

    useEffect(() => {
        setEvents(props.events);
        checkForOverflow();
        checkForScrollPosition();
        const interval = setInterval(() => {
            checkForScrollPosition();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="home-event-container">
                <div className="home-event-wrapper">
                    <div className="home-event-header">
                        <p className="home-text-caption">UPCOMING EVENTS</p>
                        <h1 className="home-text-main text-left">
                            Be a part of our Events
                        </h1>
                        <p
                            className="home-text-more"
                            onClick={() => history.push("/event")}
                        >
                            +&nbsp;VIEW ALL EVENTS
                        </p>
                    </div>
                    <div className="home-event-cards" ref={container}>
                        {events.map((article) => (
                            <EventCard {...{ key: article._id, ...article }} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventContainer;
