import { useHistory } from "react-router-dom";
import { Time, LocationOutline } from "react-ionicons";
import {
    eventDateFormat,
    eventDayTime,
    formatDateWithoutYear,
} from "../../../utils";
import "./style.css";

const EventCard = (props) => {
    const history = useHistory();
    return (
        <>
            <div className="home-event-card-container">
                <div className="home-event-card-item">
                    <div className="home-event-card-date">
                        <div className="home-event-card-day">
                            {eventDateFormat(props.fromDate).day}
                        </div>
                        <div className="home-event-card-month">
                            {eventDateFormat(props.fromDate).month}
                        </div>
                    </div>
                    <div className="home-event-card-title">{props.title}</div>
                    <div className="home-event-card-flex">
                        <Time
                            className="home-event-icon"
                            color={"#fc8e44"}
                            height="20px"
                            width="20px"
                        />
                        <div className="home-event-card-flex-item">
                            {formatDateWithoutYear(props.fromDate) ===
                            formatDateWithoutYear(props.toDate) ? (
                                <>
                                    <p>
                                        {formatDateWithoutYear(props.fromDate)}
                                    </p>
                                    <p>{eventDayTime(props.fromDate)}</p>
                                </>
                            ) : (
                                <>
                                    <p>{`${formatDateWithoutYear(
                                        props.fromDate
                                    )} - ${formatDateWithoutYear(
                                        props.toDate
                                    )}`}</p>
                                    <p>{`${eventDayTime(
                                        props.fromDate
                                    )} - ${eventDayTime(props.toDate)}`}</p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="home-event-card-flex">
                        <LocationOutline
                            className="home-event-icon"
                            color={"#fc8e44"}
                            height="20px"
                            width="20px"
                        />
                        <div className="home-event-card-flex-item">
                            <p>Event Type:&nbsp; {props.type}</p>
                        </div>
                    </div>
                    <span
                        className="home-event-card-register"
                        onClick={() => {
                            history.push(`event/${props._id}`);
                        }}
                    >
                        <a>READ MORE</a>
                    </span>
                </div>
            </div>
        </>
    );
};

export default EventCard;
