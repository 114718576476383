import { useHistory } from "react-router-dom";
import { Time, LocationOutline } from "react-ionicons";
import { eventDateFormat, eventDayTime, formatDateWithoutYear } from "../../utils";
import "./style.css";

const EventCard = (props) => {
    const history = useHistory();
    return (
        <>
            <div className="event-card-container">
                <div className="event-card-item">
                    <div className="event-card-date">
                        <div className="event-card-day">
                            {eventDateFormat(props.fromDate).day}
                        </div>
                        <div className="event-card-month">
                            {eventDateFormat(props.fromDate).month}
                        </div>
                    </div>
                    <div className="event-card-title">
                        {props.title}
                    </div>
                    <div className="event-card-flex">
                        <Time
                            className="event-icon"
                            color={"#fc8e44"}
                            height="20px"
                            width="20px"
                        />
                        <div className="event-card-flex-item">
                            {formatDateWithoutYear(props.fromDate) === formatDateWithoutYear(props.toDate) ?
                                <>
                                    <p>{formatDateWithoutYear(props.fromDate)}</p>
                                    <p>{eventDayTime(props.fromDate)}</p>
                                </>
                                :
                                <>
                                    <p>{`${formatDateWithoutYear(props.fromDate)} - ${formatDateWithoutYear(props.toDate)}`}</p>
                                    <p>{`${eventDayTime(props.fromDate)} - ${eventDayTime(props.toDate)}`}</p>
                                </>
                            }
                        </div>
                    </div>
                    <div className="event-card-flex">
                        <LocationOutline
                            className="event-icon"
                            color={"#fc8e44"}
                            height="20px"
                            width="20px"
                        />
                        <div className="event-card-flex-item">
                            <p>Event Type:&nbsp; {props.type}</p>
                            {props.location ? <p>Event Location:&nbsp; {props.location}</p> : null}
                        </div>
                    </div>
                    <span className="event-card-register"
                        onClick={() => { history.push(`${props.url}/${props._id}`); }}>
                        <a>READ MORE</a>
                    </span>
                </div>
            </div>
        </>
    );
};

export default EventCard;
