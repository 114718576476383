import NavDesktop from "./NavDesktop"
import NavMobile from "./NavMobile"


const Navbar = () =>{
    return(
        <>
        <NavDesktop/>
        <NavMobile/>
        </>
    )
}

export default Navbar;