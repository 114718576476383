import { useHistory } from "react-router-dom";
import { Result } from 'antd';
import "./style.css"
const PageNotFound = () => {
    const history = useHistory();
    return (
        <div className="page-not-found">
            <Result
                className="not-found"
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <div className="event-register" onClick={() => { history.push('/') }}>
                        <a >Back Home</a>
                    </div>}
            />
        </div>
    );
}

export default PageNotFound;