import ReactHtmlParser from "react-html-parser";
import { Modal, Button, Image, Row, Col } from 'antd';
import { getBaseUrl } from '../../../api'
import { formatDateWithoutDay } from "../../../utils"


const ContributionView = (props) => {
    const handleCancel = () => {
        props.setVisible(false);
    };

    return (
        <>
            <Modal
                title={`${formatDateWithoutDay(props.date)}`}
                centered
                visible={props.visible}
                onCancel={handleCancel}
                width={1000}
                footer={[
                    <Button key="back" type="primary" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}
            >
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                        <h1>{props.title}</h1>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                        <p>{ReactHtmlParser(props.content)}</p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                        <Image
                            placeholder={true}
                            src={`${getBaseUrl()}${props.image}`}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ContributionView;