import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import PATH from '../../../resources/paths';
import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets,
} from '../../../resources/Icons';
import { convertSlugToUrl } from '../../../resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux";

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    },
});

function SidebarComponent() {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;
    const [isOpen, setIsOpen] = React.useState()


    function onClick(path, parameters = {}) {
        push(convertSlugToUrl(path, parameters));
        setIsOpen(false)
    }

    function onLogout() {
        localStorage.clear();
        dispatch(setUser({
            isLoggedIn: false,
            email: "",
            role: "",
            id: ""
        }));
        push("/login")
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent />
            </div>
            <MenuItem
                id={PATH.dashboard}
                title='Dashboard'
                icon={IconOverview}
                onClick={() => onClick(PATH.dashboard)}
            />
            <MenuItem
                id={PATH.events}
                title='Events'
                icon={IconTickets}
                onClick={() => onClick(PATH.events)}
            />
            <MenuItem
                id={PATH.authors}
                title='Authors'
                icon={IconContacts}
                onClick={() => onClick(PATH.authors)}
            />
            <MenuItem
                id={PATH.contributions}
                title='Contributions'
                icon={IconArticles}
                onClick={() => onClick(PATH.contributions)}
            />
            <MenuItem
                id={PATH.users}
                title='Users'
                icon={IconAgents}
                onClick={() => onClick(PATH.users)}
            />
            <MenuItem
                id={PATH.articles}
                title='Articles'
                icon={IconArticles}
                onClick={() => onClick(PATH.articles)}
            />
            <MenuItem
                id={PATH.devotions}
                title='Devotions'
                icon={IconArticles}
                onClick={() => onClick(PATH.devotions)}
            />
            <MenuItem
                id={PATH.magazines}
                title='Magazines'
                icon={IconArticles}
                onClick={() => onClick(PATH.magazines)}
            />
            <MenuItem
                id={PATH.featured}
                title='Featured'
                icon={IconArticles}
                onClick={() => onClick(PATH.featured)}
            />
            <MenuItem
                id={PATH.subscription}
                items={[PATH.donationCause, PATH.donationHistory]}
                title='Donations'
                icon={IconOverview}
            >
                <MenuItem
                    id={PATH.donationCause}
                    title='Causes'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(PATH.donationCause)}
                />
                <MenuItem
                    id={PATH.donationHistory}
                    title='History'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(PATH.donationHistory)}
                />
            </MenuItem>
            <MenuItem
                id={PATH.mails}
                title='Mailings'
                items={[PATH.mailingAddress, PATH.mails]}
                icon={IconContacts}
            >
                <MenuItem
                    id={PATH.mails}
                    title='Mails'
                    level={2}
                    icon={IconArticles}
                    onClick={() => onClick(PATH.mails)}
                />
                <MenuItem
                    id={PATH.mailingAddress}
                    title='Mailing List'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(PATH.mailingAddress)}
                />
            </MenuItem>
            <MenuItem
                id={PATH.subscription}
                items={[PATH.plans, PATH.address, PATH.subscribers]}
                title='Subscriptions'
                icon={IconSubscription}
            >
                <MenuItem
                    id={PATH.address}
                    title='Address'
                    level={2}
                    icon={IconAgents}
                    onClick={() => onClick(PATH.address)}
                />
                <MenuItem
                    id={PATH.subscribers}
                    title='Subscribers'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(PATH.subscribers)}
                />
                <MenuItem
                    id={PATH.plans}
                    title='Plans'
                    level={2}
                    icon={IconContacts}
                    onClick={() => onClick(PATH.plans)}
                />
            </MenuItem>
            <div className={classes.separator}></div>
            <MenuItem
                id={PATH.settings}
                title='Settings'
                icon={IconSettings}
                onClick={() => onClick(PATH.settings)}
            />

            <MenuItem id='logout' title='Logout' icon={IconLogout} onClick={onLogout} />
        </Menu>
    );
}

export default SidebarComponent;
