const base_url = process.env.REACT_APP_BASE_URL;
const tiny_key = process.env.REACT_APP_TINY_KEY;
const razorpay_key = process.env.REACT_APP_RAZORPAY_KEY;

//Misc API
exports.getBaseUrl = () => base_url;
exports.getTinyKey = () => `${tiny_key}`
exports.getRazorpayKey = () => razorpay_key;
exports.getImage = (imageUrl) => `${base_url}${imageUrl}`
exports.getFile = (filePath) => `${base_url}${filePath}`

//Article API
const article = 'article/';
exports.getArticles = () => `${base_url}${article}`
exports.getArticle = (articleId) => `${base_url}${article}${articleId}`
exports.addArticle = () => `${base_url}${article}add`
exports.updateArticle = (id) => `${base_url}${article}update/${id}`
exports.deleteArticle = (id) => `${base_url}${article}remove/${id}`
exports.getCategories = () => `${base_url}${article}article-category`

//Magazine API
const magazine = 'magazine/'
exports.getMagazines = () => `${base_url}${magazine}`
exports.addMagazine = () => `${base_url}${magazine}add`
exports.deleteMagazine = (id) => `${base_url}${magazine}remove/${id}`
exports.sentEMagazine = (id) => `${base_url}${magazine}esubscription/sent/${id}`
exports.getESubscription = () => `${base_url}${magazine}esubscription/`
exports.getESubscriptionById = (id) => `${base_url}${magazine}esubscription/${id}`
exports.updateESubscription = (id) => `${base_url}${magazine}esubscription/update/${id}`
exports.deleteESubscription = (id) => `${base_url}${magazine}esubscription/admin-remove/${id}`
exports.unsubscribe = (id) => `${base_url}${magazine}esubscription/remove/${id}`
exports.addEMagazineSubscription = () => `${base_url}${magazine}esubscription/add`

//Subscription API
const subscription = 'subscription/'
exports.getAddress = () => `${base_url}${subscription}address`
exports.getAddressId = (id) => `${base_url}${subscription}address/${id}`
exports.addAddress = () => `${base_url}${subscription}address/add/`
exports.updateAddress = (id) => `${base_url}${subscription}address/update/${id}`
exports.deleteAddress = (id) => `${base_url}${subscription}address/remove/${id}`
exports.downloadAddress = () => `${base_url}${subscription}download-pdf`
exports.getSubscriptionPlans = () => `${base_url}${subscription}`
exports.purchaseSubscription = () => `${base_url}${subscription}purchase`
exports.getPricing = (planId, country) => `${base_url}${subscription}pricing/${planId}/${country}`
exports.subscriptionCheckout = () => `${base_url}${subscription}create-checkout-session`


//Event API
const event = 'event/'
exports.getEvents = () => `${base_url}${event}`
exports.addEvent = () => `${base_url}${event}add`
exports.getEvent = (eventId) => `${base_url}${event}${eventId}`
exports.deleteEvent = (eventId) => `${base_url}${event}remove/${eventId}`
exports.updateEvent = (eventId) => `${base_url}${event}update/${eventId}`

//Donation API
const donation = 'donation/'
exports.donationCheckout = () => `${base_url}${donation}create-checkout-session`
exports.getDonations = () => `${base_url}${donation}history`
exports.deleteDonation = (donationId) => `${base_url}${donation}history/remove/${donationId}`
exports.getDonationCauses = () => `${base_url}${donation}cause/`
exports.addDonationCause = () => `${base_url}${donation}cause/add`
exports.getDonationCause = (causeId) => `${base_url}${donation}cause/${causeId}`
exports.deleteDonationCause = (causeId) => `${base_url}${donation}cause/remove/${causeId}`
exports.updateDonationCause = (causeId) => `${base_url}${donation}cause/update/${causeId}`


//Authors
const author = 'author/'
exports.getAuthors = () => `${base_url}${author}`
exports.getAuthorById = (id) => `${base_url}${author}${id}`
exports.addAuthor = () => `${base_url}${author}add/`
exports.updateAuthor = (id) => `${base_url}${author}update/${id}`
exports.deleteAuthor = (id) => `${base_url}${author}remove/${id}`

//User API
const user = 'user/'
exports.getUsers = () => `${base_url}${user}`
exports.registerUser = () => `${base_url}${user}register`
exports.deletedeleteUserMail = (id) => `${base_url}${user}remove/${id}`
exports.login = () => `${base_url}${user}login`
exports.forgotPassword = () => `${base_url}${user}forgot-password`
exports.resetPassword = () => `${base_url}${user}reset-password`

//Mailing API
const contact = 'contactus/'
const mailing = 'mailing/'
exports.sendContactData = () => `${base_url}${mailing}${contact}`
exports.getMails = () => `${base_url}${mailing}mails`
exports.createAndSendMail = () => `${base_url}${mailing}mails/send`
exports.sendMail = (id) => `${base_url}${mailing}mails/send/${id}`
exports.deleteMail = (id) => `${base_url}${mailing}mails/remove/${id}`
exports.getMailingList = () => `${base_url}${mailing}`
exports.getMailingListId = (id) => `${base_url}${mailing}${id}`
exports.addMailingList = () => `${base_url}${mailing}add/`
exports.updateMailingList = (id) => `${base_url}${mailing}update/${id}`
exports.deleteMailingList = (id) => `${base_url}${mailing}remove/${id}`

//Gallery API
const gallery = 'gallery/'
exports.getGallery = () => `${base_url}${gallery}`


//General API
const general = 'general/'
exports.getStatistics = () => `${base_url}${general}stats`
exports.getCurrencies = () => `${base_url}${general}currency`
exports.getCountries = () => `${base_url}${general}country`
exports.getCountryCodes = () => `${base_url}general/country-code`

//Devotion API
const devotion = 'devotion/'
exports.getDevotions = () => `${base_url}${devotion}`
exports.getDevotion = (devotionId) => `${base_url}${devotion}${devotionId}`
exports.addDevotion = () => `${base_url}${devotion}add`
exports.updateDevotion = (id) => `${base_url}${devotion}update/${id}`
exports.deleteDevotion = (id) => `${base_url}${devotion}remove/${id}`
exports.getDefaultDevotion = () => `${base_url}${devotion}default`
exports.setDefaultDevotion = (devotionId) => `${base_url}${devotion}default/add/${devotionId}`
//Media API
const media = 'media/'
exports.getMedia = () => `${base_url}${media}`

//Contribution API
const contribution = 'contribution/'
exports.getContributions = () => `${base_url}${contribution}`
exports.getContribution = (id) => `${base_url}${contribution}${id}`
exports.addContribution = () => `${base_url}${contribution}add/`
exports.updateContribution = (id) => `${base_url}${contribution}update/${id}`
exports.deleteContribution = (id) => `${base_url}${contribution}remove/${id}`

//Featured API
const featured = 'featured/'
exports.getFeatured = () => `${base_url}${featured}`
exports.getFeaturedId = (id) => `${base_url}${featured}${id}`
exports.addFeatured = () => `${base_url}${featured}add/`
exports.updateFeatured = (id) => `${base_url}${featured}update/${id}`
exports.deleteFeatured = (id) => `${base_url}${featured}remove/${id}`
