import { useHistory } from "react-router-dom";
import { formatDateWithoutYear } from "../../../utils";
import "./style.css";

const ArticleCardLanding = (props) => {
    const history = useHistory();
    return (
        <>
            <div className="article-card-landing-container">
                <div className="article-card-landing-item">
                    <div className="article-card-landing-info">
                        <div className="article-card-landing-date">
                            <span className="article-card-landing-date-text">
                                {formatDateWithoutYear(props.createdAt)}
                            </span>
                        </div>
                        <div className="article-card-landing-content">
                            <div className="article-card-landing-title">
                                {props.title}
                            </div>
                            <div className="article-card-landing-text">
                                <p>
                                    {props.summary.length < 35
                                        ? `${props.summary}`
                                        : `${props.summary.substring(0, 400)}...`}
                                </p>
                            </div>
                        </div>
                        <div className="article-card-landing-readmore" onClick={() => {
                            history.push(`/article/${props._id}`);
                        }}>
                            <h3>+ READ MORE</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArticleCardLanding;
