import { useState, useEffect } from "react";
import axios from "axios";
import MagazineCard from "../MagazineCard";
import { Empty, message } from 'antd';
import PageLoading from "../../PageLoading";
import coverImage from '../../../resources/images/magazine.jpg'
import SubscribeWindow from "../../SubscribeWindow"
import { getMagazines } from "../../api";
import Pagination from "../../Pagination"
import "./style.css";


const headImage = {
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage})`,
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: 'cover',
}

const MagazinesPage = () => {
  const [magazines, setMagazines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(3)
  const [limit, setLimit] = useState(8)

  const incrimentPage = () => {
    setCurrentPage((page) => page + 1)
  }

  const decrementPage = () => {
    setCurrentPage((page) => page - 1)
  }

  const setPage = (page) => {
    setCurrentPage(page)
  }

  const fetchMagazines = () => {
    setLoading(true)
    axios
      .get(getMagazines(), { params: { pageNo: currentPage - 1, limit } })
      .then((magazines) => {
        setMagazines(magazines.data.data.magazines);
        setTotalCount(magazines.data.data.totalCount)
        setLoading(false)

      })
      .catch((err) => {
        setLoading(false)
        message.error("Unable to load Magazines")
      });
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' });
    fetchMagazines()
  }, [currentPage]);



  return (
    <div>
      <PageLoading loading={loading} />
      <div className="magazines-banner">
        <div className="magazines-img" style={headImage}>
          <div className="magazines-banner-title-wrap">
            <h1 className="magazines-banner-title">Magazines</h1>
          </div>
        </div>
      </div>

      {magazines.length == 0 ? <Empty className="empty-icon" image={Empty.PRESENTED_IMAGE_SIMPLE} description={
        <span>No Magazines Present</span>} />
        : null}

      <div className="magazines-cards-wrapper">
        <div className="magazines-cards-container">
          <div className="magazines-cards-row">
            <div className="magazines-cards-col">
              <div className="magazines-cards-inner">
                {magazines.map((magazine) => (
                  <MagazineCard key={magazine._id} {...magazine}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        goToNextPage={incrimentPage}
        goToPreviousPage={decrementPage}
        changePage={setPage}
        currentPage={currentPage}
        totalCount={totalCount}
        pageLimit={pageLimit}
        limit={limit}
      />
      <SubscribeWindow />
    </div>
  );
};

export default MagazinesPage;
