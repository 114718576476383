import { createStore } from "redux";
const userInitial = {
	isLoggedIn: false,
	email: "",
	role: "",
	id: ""
};

export function setUser(user) {
	return {
		type: "SET_USER",
		payload: user,
	};
}

const userReducer = (user = userInitial, action) => {
	switch (action.type) {
		case "SET_USER":
			return { ...user, ...action.payload }
		default:
			return user;
	}
};

const store = createStore(userReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
