import React, { useContext } from 'react';
import { string } from 'prop-types';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { SidebarContext } from '../../../hooks/useSidebar';
import PATH from '../../../resources/paths';
import { useSelector } from "react-redux";
import avatar from "../../../resources/images/avatar.png"

const useStyles = createUseStyles((theme) => ({
    avatar: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginLeft: 14
        }
    },
    container: {
        height: 40
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'right',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginLeft: 14,
            marginRight: 0
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: 50
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        }
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: 25,
        '@media (max-width: 768px)': {
            marginLeft: 12
        }
    }
}));

function HeaderComponent() {
    const userDetails = useSelector((state) => state);
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });

    let title;
    switch (true) {
        case currentItem === PATH.dashboard:
            title = 'Overview';
            break;

        case currentItem === PATH.events:
            title = 'Manage Events';
            break;
        case currentItem === PATH.donationHistory || currentItem === PATH.donationCause || currentItem === PATH.addDonationCause || currentItem.includes(PATH.updateDonationCauseCheck):
            title = 'Manage Donations';
            break;
        case currentItem === PATH.featured:
            title = 'Manage Featured';
            break;
        case currentItem === PATH.authors:
            title = 'Manage Authors';
            break;
        case currentItem === PATH.users:
            title = 'Manage Users';
            break;
        case currentItem === PATH.articles || currentItem === PATH.addArticle || currentItem.includes(PATH.updateArticleCheck):
            title = 'Manage Articles';
            break;
        case currentItem === PATH.devotions || currentItem === PATH.addDevotion || currentItem.includes(PATH.updateDevotionCheck):
            title = 'Manage Devotions';
            break;
        case currentItem === PATH.contributions || currentItem === PATH.addContribution || currentItem.includes(PATH.updateContributionCheck):
            title = 'Manage Contributions';
            break;
        case [PATH.mails, PATH.mailingAddress].includes(currentItem) || currentItem === PATH.mailsCreateAndSend:
            title = 'Manage Mailings';
            break;
        case currentItem === PATH.magazines:
            title = 'Manage Magazines';
            break;
        case [PATH.subscription, PATH.plans, PATH.address, PATH.subscribers].includes(currentItem):
            title = 'Manage Subscriptions';
            break;
        case currentItem === PATH.settings:
            title = 'Settings';
            break;
        default:
            title = '';
    }

    return (
        <Row className={classes.container} vertical='center' horizontal='space-between'>
            <span className={classes.title}>{title}</span>
            <Row vertical='center'>
                <div className={classes.separator}></div>
                <span className={classes.name}>{userDetails.email}</span>
                <img
                    src={avatar}
                    alt='avatar'
                    className={classes.avatar}
                />
            </Row>
        </Row>
    );
}

HeaderComponent.propTypes = {
    title: string
};

export default HeaderComponent;
