import { useEffect,useState } from "react";
import { getEvents } from "../../../api";
import EventContainer from "../EventContainer";
import axios from "axios"
import PageLoading from "../../../PageLoading";
import { message } from "antd"

const EventRenderer = () =>{
    const [loading,setLoading] = useState(false)
	const [events,setEvents] = useState([])
	const [totalCount,setTotalCount] = useState(0)
	const fetchEvents = () => {
		setLoading(true);
		axios
			.get(getEvents())
			.then((events) => {
				setEvents(events.data.data.events);
				setTotalCount(events.data.data.totalCount);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				message.error("Unable to load Event Details");
			});
	};

	useEffect(() => {
		fetchEvents();
	}, []);

    return(
        <>
        	<PageLoading loading={loading} />
           {totalCount>0 && <EventContainer events={events}/>} 
        </>
    )
}

export default EventRenderer