import './style.css'

const Statements = [
    "We believe in the virgin birth of our Lord Jesus Christ.",
    "We believe that salvation by grace through faith alone.",
    "We believe the literal resurrection, ascension and Second Advent of Jesus Christ.",
    "We believe in the millennium reign of Christ.",
    "We believe in the full inspiration(verbal plenary), inerrancy and infallibility of the Bible.",
    "We believe that all believers will be with Christ in eternity and all unbelievers will be thrown into eternal hell fire.",
    "We believe in the deity and sinless humanity of Jesus Christ.",
    "We believe that the church is the company of called out people from sin.",
    "We believe on the substitutionary death of Lord Jesus Christ on the Cross.",
    "We believe the literal return of Christ in the midair for the Church and the Second Advent to the earth with the Church.",

]
const statementOfFaith = () => {
    return (
        <div className="stmt-page">
            <div className="statement-banner">
                <h1 className="statement-title">Statement of Faith</h1>
            </div>
            <section className="statement-of-faith" >
                <div className="statement-container">
                    <div className="statement-content-container">
                        {Statements.map((statement, index) => {
                            let clazzName =
                                index % 2 === 0 ? "statement-content" : "statement-content grey-bg";
                            return (
                                <div className={clazzName}>
                                    <span className="statement-line"></span>
                                    <p className="statement-text-bold">{statement}</p>
                                    <span className="statement-line"></span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default statementOfFaith;