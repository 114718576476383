import "./style.css"

const missionStatements = [
  "It is to stretch the hand of the practicality of Christ’s love in the villages of India",
  "Play a significant role in the evangelization of India",
  "Support and encourage pioneer missionaries in evangelism",
  "Provide livelihood tools to find self-employment",
  "Provide timely assistance to those affected by natural disasters",
  "Provide educational assistance to the unprivileged children",
  "Provide help to those in need of marriage assistance",
  "Conduct medical camps and help financially needy patients",
  "Provide housing assistance to the homeless as much as possible",
  "Go down to the community and help inpatients"
]

const MissionAndVision = () => {
  return (
    <section className="about-us-mission" id="mission">
      <div className="mission-container">
        <div className="mission-header">
          <p className="section-caption">MISSION AND VISION</p>
          <h2 className="section-header">Setting out our Mission and Vision</h2>
        </div>
        <div className="mission-content-container">
          {missionStatements.map((statement, index) => {
            let clazzName =
              index % 2 === 0 ? "mission-content" : "mission-content grey-bg";
            return (
              <div className={clazzName}>
                <span className="mission-line"></span>
                <p className="section-text-bold">{statement}</p>
                <span className="mission-line"></span>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};


export default MissionAndVision;