import { useState } from 'react'
import axios from 'axios'
import background from "./../../resources/images/subscriptionWindow.jpg"
import BarLoader from "react-spinners/BarLoader";
import styled from 'styled-components'
import { addEMagazineSubscription } from "../api"
import { message, Form, Input, Button } from 'antd'
import logo from "./../../resources/images/logo.png"
import "./style.css"

const imageStyle = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background})`,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'cover',
}

const ButtonContainer = styled.span`
  .ant-btn-primary {
    display:inline;
    background: #fff;
    border-color: #fff;
    color: black;
  }
  &:hover {
      .ant-btn-primary {
          background: inherit;
          color:white;
        }`

const SubscribeWindow = () => {

    const [loading, setLoading] = useState(false)
    const onFinish = (userDetails) => {
        setLoading(true)
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regEmail.test(userDetails.email) && userDetails.name) {
            axios
                .post(addEMagazineSubscription(), userDetails)
                .then(async (response) => {
                    setLoading(false)
                    message.success(response.data.data)

                })
                .catch((error) => {
                    setLoading(false)
                    message.error("Unable to add your email to subscription list")
                })
        } else {
            setLoading(false)
            message.error("Please check the your Input Details.")
        }
    }

    return (
        <section style={imageStyle}>
            <div className="subscribe-window-container">
                <div className="subscribe-window-inner">
                    <div className="subscribe-window-image">
                        <img width="100px" src={logo} alt="logo" />
                    </div>
                    <div className="subscribe-window-caption">
                        <h2>Subscribe Our Magazine and Keep Up With Our <span style={{ fontStyle: "italic" }}>Latest Articles</span></h2>
                    </div>
                    <div className="subscribe-window-text">
                        <p>We are committed to Jesus Christ, the Bible, and the Great Commission.</p>
                    </div>
                    <div className="subscribe-window-form-container">
                        <Form
                            name="subscribe-window-form"
                            onFinish={onFinish}
                        >
                            <div className="subscribe-window-input">

                                <Form.Item
                                    className="subscribe-window-form-item"
                                    name="name"
                                >
                                    <Input
                                        placeholder="*Name"
                                        size="large"
                                        style={{ backgroundColor: "rgba(0,0,0,.1)", color: "white" }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="subscribe-window-form-item"
                                    name="email"
                                >
                                    <Input
                                        placeholder="*Email"
                                        size="large"
                                        style={{ backgroundColor: "rgba(0,0,0,.1)", color: "white" }}
                                    />
                                </Form.Item>
                                <Form.Item className="subscribe-window-form-item">
                                    <ButtonContainer>
                                        <Button size="large" type="primary" htmlType="submit">
                                            {loading ? <BarLoader color="#fc8e44" loading={true} height={5} width={50} /> : "Submit"}
                                        </Button>
                                    </ButtonContainer>
                                </Form.Item>

                            </div>
                        </Form>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default SubscribeWindow;