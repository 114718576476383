import { Time } from "react-ionicons";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../utils";
import { getImage } from "../../api"
import Avatar from "../../Avatar";
import "./style.css";

const ArticleCard = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="article-card-container">
        <div className="article-card-item">
          <div className="article-card-media">
            <img
              src={getImage(props.coverImage)}
              alt="book-1209805_1920"
              className="article-card-image"
            />
          </div>
          <div className="article-card-info">
            <div className="article-card-date">
              <Time
                className="article-card-date-icon"
                color={"#5e5e5e"}
                height="20px"
                width="20px"
              />
              <div className="article-card-date-text">
                {formatDate(props.createdAt)}
              </div>
            </div>
            <div
              onClick={() => {
                history.push(`${props.url}/${props._id}`);
              }}
              className="article-card-title"
            >
              {props.title}
            </div>
            <div className="article-card-text">
              <p>
                {props.summary.length < 35
                  ? `${props.summary}`
                  : `${props.summary.substring(0, 200)}...`}
              </p>
            </div>
            <div className="article-card-author">
              <div className="article-card-author-avatar">
                <Avatar
                  text={props.author.name}
                  size="small"
                  avatar={getImage(props.author.avatar)}
                />
              </div>
              <div className="ariticle-card-author-text">
                {props.author.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard;
