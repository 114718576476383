import { useEffect, useState } from "react";
import { getFeatured, getImage } from "../../api";
import axios from "axios"
import { useHistory } from "react-router-dom";
import PageLoading from "../../PageLoading";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { message } from "antd"
import "./style.css"

const FeaturedSection = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false)
	const [featured, setFeatured] = useState([])
	const [totalCount, setTotalCount] = useState(0)

	const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

	const updateMedia = () => {
		setDesktop(window.innerWidth > 1000);
	};



	const fetchFeatured = () => {
		setLoading(true);
		axios
			.get(getFeatured())
			.then((featured) => {
				setFeatured(featured.data.data.featured);
				setTotalCount(featured.data.data.totalCount);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				message.error("Unable to load Featured Content");
			});
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	useEffect(() => {
		fetchFeatured();
	}, []);


	return (
		<>
			<PageLoading loading={loading} />
			<div className="featured-container">
				<Carousel
					autoPlay={true}
					emulateTouch={false}
					dynamicHeight={true}
					interval={8000}
					showThumbs={false}
					showStatus={false}
				>
					{featured.map((content) => (
						<div className="featured-image-container" onClick={() => { history.push(`featured/${content._id}`) }}>
							<img className="featured-image" src={isDesktop ? getImage(content.desktopImage) : getImage(content.mobileImage)} />
						</div>
					))}
				</Carousel>
			</div>

		</>
	)
}

export default FeaturedSection