import "./style.css"
const Avatar = (props) => {
  const getSize = (size) => {
    switch (size) {
      case "large":
        return "60";
      case "medium":
        return "45";
      case "small":
        return "30";
      default:
        return "30";
    }
  };
  return (
    <div className="avatar-frame">
      <img
        src={props.avatar}
        width={getSize(props.size)}
        height={getSize(props.size)}
        alt={props.text}
        className="avatar-image"
      />
    </div>
  );
};

export default Avatar;
