import { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import axios from 'axios'
import {
  getCurrencies,
  donationCheckout,
  getRazorpayKey,
  getBaseUrl
} from '../../api'
import { loadScript } from '../../utils'
import PageLoading from "../../PageLoading";
import coverImage from "../../../resources/images/donation.jpg";
import { Form, Input, Row, Col, Button, Select, message } from 'antd'
import { toLower } from "../../utils"
import { useStripe } from '@stripe/react-stripe-js'
import './style.css'
import SubscribeWindow from '../../SubscribeWindow';
const { Option } = Select;

const headImage = {
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage}) no-repeat center`,
  backgroundSize: 'cover',
}

const DonationGeneral = (props) => {
  const history = useHistory();
  const [donationDetails, setDonationDetails] = useState({})
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0)
  const [currency, setCurrency] = useState([])
  const [donationForm] = Form.useForm()
  const stripe = useStripe()

  const currencySupported = () => {
    setLoading(true)
    axios
      .get(getCurrencies())
      .then((currencies) => {
        setLoading(false)
        setCurrency(currencies.data.data)
      })
      .catch((err) => {
        setLoading(false)
        message.error("Unable to load supported currencies")
      })
  }

  const onDonationSubmit = (values) => {
    setDonationDetails(values)
    setActiveStep((step) => step + 1)
    window.scrollTo({ behavior: 'smooth', top: '20px' });
  }

  const prevStep = () => {
    setActiveStep((step) => step - 1)
  }
  const createCheckoutSession = () => {
    setLoading(true)
    const data = {
      donationDetails,
    }
    axios
      .post(donationCheckout(), data)
      .then(async (response) => {
        setLoading(false)
        const { paymentGateway, sessionId, donationDetails } = response.data.data
        if (paymentGateway === 'stripe') {
          const { error } = await stripe.redirectToCheckout({ sessionId })
          if (error) console.log(error)
        } else if (paymentGateway === 'razorpay') {
          const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
          console.log(res)
          if (!res) {
            message.error('Razorpay SDK failed to load. Are you online?')
            return
          }

          const options = {
            key: getRazorpayKey(),
            currency: donationDetails.currency,
            amount: donationDetails.amount,
            order_id: sessionId,
            name: 'Good Sheperd Ministries Donation',
            description: "Donation to Good Shephed Ministries",
            image: `${getBaseUrl()}assets/images/logo.jpg`,
            handler: function (response) {
              console.log(response)
              if (!(typeof response.razorpay_payment_id == 'undefined' || response.razorpay_payment_id < 1)) {
                history.push(`/donation/success/${response.razorpay_payment_id}`)
              }
            },
            prefill: {
              name: donationDetails.name,
              email: donationDetails.email,
              phone_number: donationDetails.phoneNumber
            }
          }
          const paymentObject = new window.Razorpay(options)
          paymentObject.on('payment.failed', function (response) {
            history.push(`/donation/canceled`)
          });
          paymentObject.open()
        }
      })
      .catch((error) => {
        setLoading(false)
        message.error("An error occured while making Payment. Try Again")
      })
  }

  const currencySelected = () => {
    if (currency.find(x => x._id === donationDetails.currency))
      return currency.find(x => x._id === donationDetails.currency).label
    return ""
  }

  useEffect(() => {
    currencySupported()
  }, [])

  const prefixSelector = (
    <Form.Item
      name="currency"
      noStyle
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select
        placeholder="Currency"
        showSearch
        filterOption={(input, option) =>
          option.props.label !== null
          && toLower(option.props.label).includes(toLower(input))
        }
        style={{
          width: 120,
        }}
      >
        {currency.map(item => <Option
          label={item.label}
          key={item._id}
          value={item._id}
        >{item.label}</Option>)}

      </Select>
    </Form.Item>
  );

  const displayForms = () => {
    if (activeStep === 0) {
      return (
        <Form
          size="large"
          layout={"vertical"}
          form={donationForm}
          name="donationForm"
          onFinish={onDonationSubmit}
          scrollToFirstError
          className="donation-form"
        >
          <h2 className="donation-form-sub-title">Personal Info</h2>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Name',
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[{
                  pattern: /^(?:\d*)$/,
                  message: "Amount should contain only Numbers",
                },
                {
                  required: true,
                  message: 'Please input amount!',
                },
                ]}
              >
                <Input
                  addonAfter={prefixSelector}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              DONATE
            </Button>
          </Form.Item>
        </Form>
      )
    } else if (activeStep === 1) {
      return (
        <>
          <h2 className="subscription-form-sub-title">Donation Info</h2>
          <h2 className="subscription-plan-total">
            Total amount:&nbsp;{donationDetails.amount}&nbsp;
            {currencySelected()}
          </h2>
          <Row gutter={8}>
            <Col span={4}>
              <Button type="primary" onClick={prevStep} >Prev</Button>
            </Col>
            <Col span={4}>
              <Button type="primary" onClick={createCheckoutSession}>Go to Payment</Button>
            </Col>
          </Row>
        </>
      )
    }
  }

  return (
    <>
      <PageLoading loading={loading} />
      <div className="donation-page-container">
        <div className="donation-banner">
          <div className="donation-img" style={headImage}>
            <div className="donation-banner-title-wrap">
              <h1 className="donation-banner-title">Donation</h1>
            </div>
          </div>
        </div>
        <div className="donation-container donation-gen-container">
          <div className="donation-form-container">
            <h1 className="donation-form-title">
              Donation Form
            </h1>

            <div className="donation-form-wrap">  {displayForms()}     </div>
          </div>
        </div>
        <SubscribeWindow />
      </div>
    </>
  )
}

export default DonationGeneral
