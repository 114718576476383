import { useEffect } from "react"
import { Form, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux";
import getUser from "../../getUser"
import avatar from "../../../resources/images/avatar.png";
import { login } from "../../api"
import "./style.css"
import axios from 'axios';


const LoginPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        let token = localStorage.getItem("token")
        if (token) {
            dispatch(setUser(getUser(token)))
            history.push("/dashboard")
        }
    }, [])

    const onFinish = (values) => {
        let credentials = {
            email: values.email,
            password: values.password
        }
        axios.post(login(), credentials).then(response => {
            localStorage.setItem("token", response.data.data.token);
            dispatch(setUser(getUser(response.data.data.token)));
            history.push("/dashboard")
        }).catch(err => {
            if (err.response.status == 404) message.error(err.response.data.message)
            else message.error("Unable to Login right now.")
        })
    };
    return (
        <div>
            <div className="login-container">
                <div className="login-form-container">
                    <img
                        width={100}
                        height={100}
                        src={avatar}
                        alt="avatar"
                        className="login-avatar"
                    />

                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                type="email"
                                placeholder="Email"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item>

                            <a className="login-form-forgot" href="/forgot-password">
                                Forgot password ?
                        </a>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Log in
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>

    );
}

export default LoginPage;

