import './style.css'
import Img1 from "./image1.png"
import Img2 from "./image2.png"
import Img3 from "./image3.png"
import Img4 from "./image4.png"
import Img5 from "./image5.png"
const DonateSection = () => {
    return (
        <div className="donate-section-wrapper">
            <div className="donate-section-container">
                <div className="donate-section-images">
                    <img src={Img1} alt="img1" className="donate-section-image-1"></img>
                    <img src={Img2} alt="img2" className="donate-section-image-2"></img>
                    <img src={Img3} alt="img3" className="donate-section-image-3"></img>
                    <img src={Img4} alt="img4" className="donate-section-image-4"></img>
                    <img src={Img5} alt="img5" className="donate-section-image-5"></img>
                </div>
                <div className="donate-section-content">
                    <p className="home-text-caption">IT'S IMPORTANT</p>
                    <h1 className="home-text-main">Making a Donation
                        You Help All These People</h1>
                    <p className="home-text-paragraph">The Good Shepherd ministries (Nallaedayn Ministries) is a movement formed in
                        New York, USA in 2001. The movement was started by the brethren who immigrated to the United States from
                        Kerala, the southernmost state of India.</p>
                    <a href="/donation/general"><button className="home-section-button" >MAKE A ONE TIME DONATION</button></a>
                </div>
            </div>
        </div>
    )
}

export default DonateSection