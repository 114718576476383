import "./style.css"
import Butterfly from "../../../resources/images/about-us-butterfly.svg"

const MovingForward = () => {
  return (
    <section className="about-us-qoute">
      <div className="about-us-moving">
        <p className="section-caption">MOVING FORWARD</p>
        <p className="section-text">
          Nalla Edayan is a ministry started by God with the fervent prayers,
          dedication, effort of the far-sighted brothers. By God’s grace
          ministry has come thus far. This movement was able to maintain its
          beginnings without deviating from the vision and without changing with
          the times, without mixing doctrinal subjects. There is still a lot to
          be done in India. We would like to start a palliative care unit to
          provide relief to those who are bedridden at home. Participate in this
          blessed ministry and pray.
        </p>
      </div>
      <div className="qoute-container">
        <p className="section-text">
          The Gospel of John Chapter 10:11 records the words of the Lord Jesus
          Christ,
        </p>
        <div className="qoute-qoute">
          <img src={Butterfly} alt="Butterfly" />
          <h2 className="qoute-main-qoute">
            “ I am the Good Shepherd, the Good Shepherd gives his life for the
            sheep ”
          </h2>
        </div>
        <p className="section-text">
          Our Good Shepherd left His glory in Heaven and came down to wretched
          earth to seek and to save the lost sheep. As saved and redeemed sheep
          of God, we have the responsibility to spread this redemption story, as
          the Bible instructs us to. This was the initial aim of the Good
          Shepherd ministry. For the last 20 years, this ministry has been in
          the forefront to achieve this goal. The ministry has been able to
          support and encourage the frontline workers in the Lord’s Vineyard.
        </p>
      </div>
    </section>
  );
};


export default MovingForward;