import SubscribeWindow from "../SubscribeWindow";
import coverImage from "./../../resources/images/home-banner.jpg";
import AboutSection from "./AboutSection";
import DailyDevotion from "./DailyDevotion/index";
import ArticleSection from "./ArticleSection";
import { BackTop } from 'antd';
import EventRenderer from "./EventSection/EventRenderer";
import FeaturedSection from "./FeaturedSection";
import "./style.css";

const headImage = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${coverImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // width: '100%'
};

const HomePage = () => {
    return (
        <>
            <div className="home-container">
                <div className="home-banner" style={headImage}>
                    <div className="home-title-container">
                        <div className="home-caption">
                            <p>Welcome</p>
                            <div></div>
                            <h1>Good Shepherd Ministries</h1>
                        </div>
                        <h1 className="home-title">
                            The Lord is my Shepherd; I shall not Want
                        </h1>
                    </div>
                </div>
            </div>
            <DailyDevotion />
            <AboutSection />
            <FeaturedSection />
            <EventRenderer />
            <SubscribeWindow />
            <ArticleSection />
            <BackTop />
        </>
    );
};

export default HomePage;
