import { useHistory } from "react-router-dom";
import { getImage } from "../../api"
import "./style.css";

const DonationCard = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="donation-card-container">
        <div className="donation-card-item">
          <div className="donation-card-media">
            <img
              src={getImage(props.coverImage)}
              alt="book-1209805_1920"
              className="donation-card-image"
            />
          </div>
          <div className="donation-card-info">
            <div
              className="donation-card-title"
            >
              {props.title}
            </div>
            <div className="donation-card-button">
              <div className={`navbar-donate navbar-donate-default`}
                onClick={() => { history.push(`${props.url}/${props._id}`); }}>
                <a >DONATE NOW</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationCard;
