import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Tag from "../../Tag";
import Avatar from "../../Avatar";
import PageLoading from "../../PageLoading";
import { getBaseUrl, getArticle ,getImage } from "../../api";
import { formatDate } from "../../utils";
import { message } from 'antd'
import {
	FacebookShareButton,
	WhatsappShareButton,
	TelegramShareButton,
} from "react-share";
import { FacebookIcon, WhatsappIcon, TelegramIcon } from "react-share";
import ReactHtmlParser from "react-html-parser";
import "./style.css";

const Article = () => {
	const { id } = useParams();
	const history = useHistory();
	const [loading, setLoading] = useState(true);
	const [article, setArticle] = useState({});

	const fetchArticle = () => {
		setLoading(true)
		axios.get(getArticle(id))
			.then((article) => {
				setArticle(article.data.data);
				setLoading(false)
			}).catch(err => {
				setLoading(false)
				if (err.response.status === 404) history.push("/404")
				else message.error("Unable to load Article Details")

			});
	};

	useEffect(() => {
		fetchArticle();
	}, []);

	return (
		<>
			<PageLoading loading={loading} />
			{Object.keys(article).length ?
				<div className="article-container">
					<div className="article-head" style={{ background: `url(${getBaseUrl()}${article.coverImage}) no-repeat center` }}>
						<div className="article-head-inner article-container-gen">
							<div className="article-head-top"></div>
							<div className="article-head-middle">
								<div className="article-category" onClick={() => {
										history.push(`/article`);
									}}>
									<a>{article.category.name}</a>
								</div>
								<h1 className="article-title">{article.title}</h1>
								<p className="article-date">{formatDate(article.createdAt)}</p>
							</div>
							<div className="article-head-bottom">
								<Avatar
									size="large"
									avatar={getImage(article.author.avatar)}
									text={article.author.name}
								/>
								<h6 className="article-author-text">{article.author.name}</h6>
							</div>
						</div>
					</div>
					<div className="article-content">
						<div className="article-content-wrapper article-container-gen">
							{ReactHtmlParser(article.content)}
							<div className="article-meta article-container-gen">
								<div className="article-tags">
									<span className="article-tags-title">Tags: </span>
									{article.tags.map((tag, i) => [
										i > 0 ? <span>,&nbsp;</span> : <span>&nbsp;</span>,
										<Tag
											key={i}
											tag={tag}
											onClick={() => {
												history.push("/article");
											}}
										/>,
									])}
								</div>
							</div>
							<div className="article-social-share">
								<div className="share-button">
									<WhatsappShareButton
										url={`${window.location.href}`}
										quote={`${article.title}`}
										hashtag="#biblicalthoughts"
									>
										<WhatsappIcon logoFillColor="white" round={true} size={50} />
									</WhatsappShareButton>
								</div>
								<div className="share-button">
									<FacebookShareButton
										url={`${window.location.href}`}
										quote={`${article.title}`}
										hashtag="#biblicalthoughts"
									>
										<FacebookIcon logoFillColor="white" round={true} size={50} />
									</FacebookShareButton>
								</div>
								<div className="share-button">
									<TelegramShareButton
										url={`${window.location.href}`}
										quote={`${article.title}`}
										hashtag="#biblicalthoughts"
									>
										<TelegramIcon logoFillColor="white" round={true} size={50} />
									</TelegramShareButton>
								</div>
							</div>
						</div>
					</div>
				</div> : null}
		</>
	)
};

export default Article;
